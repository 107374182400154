export const MANAGEMENT_OFFICE_TYPE = 'App\\Models\\ManagementOffice';
export const SUB_MANAGEMENT_OFFICE_TYPE = 'App\\Models\\SubManagementOffice';
export const INSTALLATION_OFFICE_TYPE = 'App\\Models\\InstallationOffice'
export const OPERATION_OFFICE_TYPE = 'App\\Models\\OperationOffice';
export const DEPARTMENT_OFFICE_TYPE = 'App\\Models\\Department';

export const MANAGER_ROLE_ID = 1;
export const DRIVER_ROLE_ID = 2;
export const DEFAULT_SELECTED_OFFICE = {
  id: null,
  type: "",
  name: "",
  departments: [],
  authorities: [],
};
