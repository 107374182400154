
<template v-if="item">
  <div class="row mr-3 ml-3 v-theme--light google-map">
    <GMapMap
      ref="map"
      :center="position()"
      :zoom="15"
      map-type-id="roadmap"
      style="width: 100%;"
      :options="mapOptions"
      @click="getPosition($event)"
    >
      <GMapCircle
        :radius="radius()"
        :center="position()"
        :options="circleOptions"
      />
      <GMapCluster>
        <GMapMarker
          :position="position()"
          :clickable="false"
          :draggable="draggable"
          @dragend="getPosition($event)"
        />
      </GMapCluster>
    </GMapMap>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: null,
    readonly: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: true
    },
  },
  data: () => ({
    item: {
      radius: '0',
      position: { lat: 36.204824, lng: 138.252924 },
    },
    center: { lat: 36.204824, lng: 138.252924 },
    mapOptions: {
      gestureHandling: 'cooperative'
    },
    circleOptions: {
      strokeColor: '#91CE4C',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#91CE4C',
      fillOpacity: 0.35
    },
  }),
  methods: {
    position() {
      const position = { lat: 36.204824, lng: 138.252924 }
      if (this.item?.latitude) position.lat = Number(this.item.latitude)
      if (this.item?.longitude) position.lng = Number(this.item.longitude)
      return position
    },
    radius() {
      if (isNaN(Number(this.item.radius))) return 0
      return Number(this.item.radius)
    },
    getPosition(event) {
      if (this.readonly) {
        return;
      }
      this.item['latitude'] = Number(event.latLng.lat())
      this.item['longitude'] = Number(event.latLng.lng())
      this.$emit('changeLatlng', this.item)
    }
  },
  watch: {
    modelValue: {
      handler() {
        this.item = JSON.parse(JSON.stringify(this.modelValue));
      },
      deep: true
    },
  },
}
</script>

<style>
.google-map {
  height: 500px;
}
</style>
