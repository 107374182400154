<template>
  <template v-if="!!formInputs?.form?.common">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">
        {{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.title') }}
      </v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <Input
              :label="i18n.global.t('pages.vehicles.deviceId')"
              v-model="form.machine_id"
              :isShow="formFieldOptions.machineId.isShow"
              :isRequired="formFieldOptions.machineId.isRequired"
              :formInputs="formInputs"
              :readonly="true"
              :errors="formFieldOptions.machineId.errors"
          />
          <template v-for="(title, key) in keys" v-bind:key="key">
            <Input
              :label="title"
              v-model="form[key]"
              :isShow="formFieldOptions[key].isShow"
              :isRequired="formFieldOptions[key].isRequired"
              :formInputs="formInputs"
              :readonly="selectDefault && readonlyInputs.includes(key)"
              :errors="formFieldOptions[key].errors"
              v-if="!isOperationButtonKeys(key) && !isPoweroffKeys(key) && !isLongDriveInterruptionMode(key)"
            >
              <template v-if="key === 'overwrite_deny'">
                <pre style="color: blue">
{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.allAllow') }}
{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.allDeny') }}
{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.normalDeny') }}
{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.eventDeny') }}
{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.digitachoDeny') }}
                </pre>
              </template>
            </Input>
          </template>
        </div>

        <div class="mr-3 v-theme--light">
          <Toggle
              :label="keys['automatic_cancellation_on']"
              v-model="form['automatic_cancellation_on']"
              :formInputs="formInputs"
              :isShow="formFieldOptions['automatic_cancellation_on'].isShow"
              :isRequired="formFieldOptions['automatic_cancellation_on'].isRequired"
              :disabled="selectDefault && readonlyInputs.includes('automatic_cancellation_on')"
          />
        </div>
        <div class="row align-items-center no-padding" v-if="form['automatic_cancellation_on'] === 'ON'">
          <template v-for="(title, key) in filteredOperationButtonKeys" v-bind:key="key">
          <div class="col">
            <Input
                type="number"
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :min="1"
                :max="operationButtonMax(key)"
                :colClassNumber="0"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes('key')"
                :errors="formFieldOptions[key].errors"
                v-if="key !== 'automatic_cancellation_on'"
            />
          </div>
          </template>
      </div>

      <div class="mr-3 v-theme--light">
        <Toggle
            :label="keys['delay_poweroff_on']"
            v-model="form['delay_poweroff_on']"
            :formInputs="formInputs"
            :isShow="formFieldOptions['delay_poweroff_on'].isShow"
            :isRequired="formFieldOptions['delay_poweroff_on'].isRequired"
            :disabled="selectDefault && readonlyInputs.includes('delay_poweroff_on')"
        />
      </div>
      <div class="row align-items-center no-padding" v-if="form['delay_poweroff_on'] === 'ON'">
        <template v-for="(title, key) in filteredPoweroffButtonKeys" v-bind:key="key">
          <div class="col">
            <Input
                type="number"
                :label="title"
                :title="poweroffVoltageTitle(key)"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :min="poweroffMin(key)"
                :max="poweroffMax(key)"
                :isDecimal="key === 'delay_poweroff_voltage'"
                :colClassNumber="0"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes('key')"
                :errors="formFieldOptions[key].errors"
            />
          </div>
        </template>
      </div>

      <template v-if="false">
        <v-divider></v-divider>

        <div class="fs-6 fw-bold mt-4 mb-4 opacity-75">{{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.longDriveLabel') }}</div>
        <div class="row align-items-center no-padding">
          <div class="col">
            <v-select
                hide-details="auto"
                class="small mt-2"
                v-model="form['long_drive_interruption_mode']"
                :items="selectLongDriveInterruptionMode"
                :clearable="false"
                :disabled="selectDefault && readonlyInputs.includes('long_drive_interruption_mode') && readonlyInputs.includes('long_drive_interruption_mode')"
            >
              <template #label>
                {{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.longDriveInterruptionMode') }}
                <span v-if="formFieldOptions['long_drive_interruption_mode'].isRequired" class="text-red">
                <strong>*</strong>
              </span>
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select
                hide-details="auto"
                class="small mt-2"
                v-model="form['long_drive_interruption_counter_reset']"
                :items="selectLongDriveCounterReset"
                :clearable="false"
                :disabled="selectDefault && readonlyInputs.includes('long_drive_interruption_counter_reset') && readonlyInputs.includes('long_drive_interruption_counter_reset')"
            >
              <template #label>
                {{ i18n.global.t('pages.vehicles.form.iniFileSetting.common.longDriveInterruptionCounterReset') }}
                <span v-if="formFieldOptions['long_drive_interruption_counter_reset'].isRequired" class="text-red">
                <strong>*</strong>
              </span>
              </template>
            </v-select>
          </div>
        </div>
      </template>

      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';
import Toggle from "@/components/VehicleFormParts/SettingFormToggle.vue";
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
    selfOfficeType: null,
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.common',
      selectOperationButtonOnOff: [
       { title: 'ON', value: 'ON' },
       { title: 'OFF', value: 'OFF' },
      ],
      selectLongDriveInterruptionMode: [
        { title: i18n.global.t('pages.vehicles.form.iniFileSetting.common.selectLongDriveInterruptionModeA'), value: 'A' },
        { title: i18n.global.t('pages.vehicles.form.iniFileSetting.common.selectLongDriveInterruptionModeB'), value: 'B' },
      ],
      selectLongDriveCounterReset: [
        { title: i18n.global.t('pages.vehicles.form.iniFileSetting.common.selectLongDriveCounterReset1'), value: '1' },
        { title: i18n.global.t('pages.vehicles.form.iniFileSetting.common.selectLongDriveCounterReset2'), value: '2' },
      ],
      keys: {
        ftp_on: i18n.global.t('pages.vehicles.form.iniFileSetting.common.ftpOn'),
        ftp_id: i18n.global.t('pages.vehicles.form.iniFileSetting.common.ftpId'),
        ftp_pass: i18n.global.t('pages.vehicles.form.iniFileSetting.common.ftpPass'),
        alert_idling_first_sec: i18n.global.t('pages.vehicles.form.iniFileSetting.common.alertIdlingFirstSec'),
        alert_idling_second_sec: i18n.global.t('pages.vehicles.form.iniFileSetting.common.alertIdlingSecondSec'),
        volume: i18n.global.t('pages.vehicles.form.iniFileSetting.common.volume'),
        alert_idling_sound: i18n.global.t('pages.vehicles.form.iniFileSetting.common.alertIdlingSound'),
        gps_dr: i18n.global.t('pages.vehicles.form.iniFileSetting.common.gpsDr'),
        camera_on: i18n.global.t('pages.vehicles.form.iniFileSetting.common.cameraOn'),
        overwrite_deny: i18n.global.t('pages.vehicles.form.iniFileSetting.common.overwriteDeny'),
        wakeup_update: i18n.global.t('pages.vehicles.form.iniFileSetting.common.wakeupUpdate'),
        upload_trip_data: i18n.global.t('pages.vehicles.form.iniFileSetting.common.uploadTripData'),
        automatic_cancellation_on: i18n.global.t('pages.vehicles.form.iniFileSetting.common.automaticCancellationOn'),
        automatic_cancellation_speed: i18n.global.t('pages.vehicles.form.iniFileSetting.common.automaticCancellationSpeed'),
        automatic_cancellation_time: i18n.global.t('pages.vehicles.form.iniFileSetting.common.automaticCancellationTime'),
        delay_poweroff_on: i18n.global.t('pages.vehicles.form.iniFileSetting.common.delayPoweroffOn'),
        delay_poweroff_time: i18n.global.t('pages.vehicles.form.iniFileSetting.common.delayPoweroffTime'),
        delay_poweroff_voltage: i18n.global.t('pages.vehicles.form.iniFileSetting.common.delayPoweroffVoltage'),
        long_drive_interruption_mode: i18n.global.t('pages.vehicles.form.iniFileSetting.common.longDriveInterruptionMode'),
        long_drive_interruption_counter_reset: i18n.global.t('pages.vehicles.form.iniFileSetting.common.longDriveInterruptionCounterReset'),
      },
    };
  },
  methods: {
    isOperationButtonKeys(key) {
      return key === 'automatic_cancellation_on' || key === 'automatic_cancellation_speed' || key === 'automatic_cancellation_time';
    },
    isPoweroffKeys(key) {
      return key === 'delay_poweroff_on' || key === 'delay_poweroff_time' || key === 'delay_poweroff_voltage';
    },
    isLongDriveInterruptionMode(key) {
      return key === 'long_drive_interruption_mode' || key === 'long_drive_interruption_counter_reset'
    },
    operationButtonMax(key) {
      return key === 'automatic_cancellation_speed' ? 20 : 30;
    },
    poweroffMin(key) {
      return key === 'delay_poweroff_time' ? 1 : 10.0;
    },
    poweroffMax(key) {
      return key === 'delay_poweroff_time' ? 30 : 24.0;
    },
    poweroffVoltageTitle(key) {
      return key === 'delay_poweroff_voltage' ? 'The acceptable input range is ' + this.poweroffMin(key).toFixed(1) + ' to ' +  this.poweroffMax(key).toFixed(1) + '.' : ''
    },
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    filteredOperationButtonKeys() {
      const allowedKeys = [
        'automatic_cancellation_speed',
        'automatic_cancellation_time'
      ];

      return Object.fromEntries(
          Object.entries(this.keys).filter(([key]) => allowedKeys.includes(key))
      );
    },
    filteredPoweroffButtonKeys() {
      const allowedKeys = [
        'delay_poweroff_time',
        'delay_poweroff_voltage'
      ];

      return Object.fromEntries(
          Object.entries(this.keys).filter(([key]) => allowedKeys.includes(key))
      );
    },
    formFieldOptions() {
      const parentKey = 'common'

      const options = {
        machineId: this.getShowAndRequiredAndErrors(parentKey, ['machine_id']),
      };

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key]);
      });

      return options;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue;
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.common) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.common
        );
      },
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form);
      },
      deep: true,
    },
  },
  components: {
    Toggle,
    Input,
  },
};
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
.no-padding {
  padding: 0 !important;
}
</style>
