import { Loader } from '@googlemaps/js-api-loader';
import EventType from '@/types/DeliveryPlanEvent';


export const getAddress = async(event: EventType): Promise<string|null> => {
    // <GMapMap></GMapMap>を使用しないので別ライブラリからgoogleオブジェクトを呼び出す必要あり
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY ?? '',
    version: "weekly",
    libraries: ['geocoding']
  });
  const { Geocoder } = await loader.importLibrary("geocoding");
  const geocoder = new Geocoder();

  if(event.latitude && event.longitude) {
    const latlng = {
      lat: Number(event.latitude),
      lng: Number(event.longitude)
    }
    try {
    const { results } = await geocoder.geocode({ location: latlng })
      if (results && results[0]) {
        const address = results[0].formatted_address;
        return Promise.resolve(removePostingCode(address))
      }
    } catch(err) {
      console.log(err)
    }
  }
  return Promise.resolve(null);
}
const removePostingCode = (address: string): string => {
  return address.replace(/.*〒[\d-]+ ?/, '')
}
