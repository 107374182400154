<template>
  <div class="d-flex" style="min-height: 45vw">
    <div class="content p-4 w-100">
      <h4 class="card-title mt-0">{{ title }}</h4>
      <div class="row mr-3 ml-3 v-theme--light">
        <div
          class="col-5"
          :class="
            !errors.managementOffice || errors.managementOffice?.length == 0
              ? ''
              : 'error'
          "
        >
          <ManagementOfficeAutoComplete
            v-model="form.managementOffice"
            :error-messages="errors.management_officeable_id"
            selectFirstIfOnlyOne
            :initialManagementId="initialManagementId"
            :initialManagementType="initialManagementType"
            :clearable="managementOfficeClearable"
          />
        </div>
        <div
          class="col-5"
          :class="
            !errors.operationOffice || errors.operationOffice?.length == 0
              ? ''
              : 'error'
          "
        >
          <SingleOperationOfficeAutoComplete
            :label="i18n.global.t('pages.vehicles.operationOffice')"
            v-model="form.operationOffice"
            :managementOffice="form.managementOffice"
            :error-messages="errors.operation_office_id"
            selectFirstIfOnlyOne
            :initialOperationId="initialOperationId"
            :clearable="operationOfficeClearable"
          />
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <v-file-input
            :label="i18n.global.t('pages.vehicles.form.vehicleVerifyImage')"
            v-model="vehicle_verify_image"
            truncate-length="15"
            :error-messages="errors.img"
          />
          <Thumbnail
            :image="form.vehicle_verify_image"
            :newImage="vehicle_verify_image"
            modelName="vehicles"
            :modelId="$route.params.id"
          />
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <v-text-field
            class="pt-2"
            v-model="form.frames_number"
            :error-messages="errors.frames_number"
          >
            <template v-slot:label>
              {{ i18n.global.t('pages.vehicles.framesNumber') }}
              <span style="color: red">
                <strong>*</strong>
              </span>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <v-text-field
            class="pt-2"
            v-model="form.vehicle_inspection_information"
            :error-messages="errors.vehicle_inspection_information"
          >
            <template v-slot:label>
              {{ i18n.global.t('pages.vehicles.form.vehicleInspectionInformation') }}
              <span style="color: red">
                <strong>*</strong>
              </span>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <v-text-field
            class="pt-2"
            v-model="form.number_plate"
            :error-messages="errors.number_plate"
          >
            <template v-slot:label>
              {{ i18n.global.t('pages.vehicles.numberPlate') }}
              <span style="color: red">
                <strong>*</strong>
              </span>
            </template>
          </v-text-field>
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <v-text-field
            class="pt-2"
            :label="i18n.global.t('pages.vehicles.registrationNumber')"
            v-model="form.registration_number"
          />
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-9">
          <v-text-field
            class="pt-2"
            :label="i18n.global.t('pages.vehicles.form.mileageMeter')"
            v-model="form.mileage_meter"
          />
        </div>
        <div class="col-1" style="position: relative">
          <p style="position: absolute; bottom: 0px; margin: 0px">km</p>
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <DigitacoMappingAutoComplete
            v-if="loaded"
            v-model="form.digital_tacograph"
            :initialItemId="initialDigitacoMappingId"
            :operationOffice="form.operationOffice"
            :errorMessages="errors.digital_tacograph"
            :readonly="isOperationOffice"
          />
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <DigitalTacographFWCopyAutoComplete
            v-if="loaded"
            v-model="form.digital_tacograph_FW_copy"
            :initialItemId="initialDigitalTacographFWCopyId"
            :operationOffice="form.operationOffice"
            :error="errors.digital_tacograph_FW_copy"
            :readonly="isOperationOffice"
          />
        </div>
      </div>
      <div class="row mr-3 ml-3 v-theme--light">
        <div class="col-10">
          <DepartmentAutoComplete
            v-model="form.department"
            :operationOffices="operationOffices"
            :initialItemId="form.department_id"
          />
        </div>
      </div>
      <IniFileSetting
        v-if="vehicleForm"
        :item="form"
        :initialModel="initialModel"
        :selectedDevice="form.digital_tacograph"
        :selectedOperationOffice="form?.operationOffice"
        :selectedSimType="simType"
        :selectedAccesspoint="accessPoint"
        v-model="form.parameters"
        :errors="errors"
        :selfOfficeType="selfOfficeType"
        :vehicleForm="vehicleForm"
        :isOperationOffice="isOperationOffice"
        :isLatestStatus="isLatestStatus"
        @changeAccessPoint="changeAccessPoint"
        @changeSimType="changeSimType"
        @changeIsLatestStatus="changeIsLatestStatus"
        @updateConfigHash="updateConfigHash"
      ></IniFileSetting>
      <div class="d-flex justify-content-end m-3">
        <v-btn
          v-if="showableUpdateButton && isAuthority"
          class="m-1 exec-btn"
          @click="submit"
          :loading="running"
          :disabled="hasItemError || hasError"
        >
          {{ i18n.global.t('pages.common.button.conservation') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import DepartmentAutoComplete from '@/components/AutoCompletes/DepartmentAutoComplete';
import DigitacoMappingAutoComplete from '@/components/VehicleFormParts/DigitacoMappingAutoComplete';
import DigitalTacographFWCopyAutoComplete from '@/components/VehicleFormParts/DigitalTacographFWCopyAutoComplete';
import IniFileSetting from '@/components/VehicleFormParts/IniFileSetting';
import Thumbnail from '@/components/Common/ImagethumbnailDisplay';
import Api from '@/services/api/ApiServiceFabrick';
import AuthorityCacheApi from '@/services/models/AuthorityCacheApi';
import { useAuth } from '@/services/user/Auth0UserProvider';
import { ObjectToFormData, deleteParams } from '@/services/functions';
import { scrollToErrorComponent } from '@/services/validators.ts';
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    showFlash: {
      type: Function,
    },
    switchContentLoading: {
      type: Function,
    },
  },
  data() {
    return {
      form: {
        parameters: {},
      },
      initialModel: null,
      isCreating: false,
      title: '',
      loginWithRedirect: null,
      errors: {},
      vehicle_verify_image: [],
      selfOfficeType: null,
      digitalTacographFWCopyId: null,
      vehicleForm: null,
      showableUpdateButton: false,
      isAuthority: false,
      loaded: false,
      running: false,
      simType: null,
      accessPoint: null,
      isOperationOffice: false,
      isLatestStatus: true,
      configHash: null,
      initialOperationId: undefined,
      initialManagementId: undefined,
      initialManagementType: undefined,
      initialDepartmentId: undefined,
      initialDigitacoMappingId: undefined,
      initialDigitalTacographFWCopyId: undefined,
      deletekeys: ['operationOffice', 'managementOffice', 'sim'],
      hasError: false,
      hasItemError: false
    };
  },
  mounted() {
    this.switchContentLoading(true);
    this.isCreating = !this.$route.params.id;
    this.title = this.isCreating ?
      i18n.global.t('pages.vehicles.title.create') :
      i18n.global.t('pages.vehicles.title.edit');
    document.title = this.title;
    Api()
      .getSelfAccount()
      .then((data) => {
        this.selfOfficeType = data?.login_organization_type;
        this.isOperationOffice =
          this.selfOfficeType == 'App\\Models\\OperationOffice' ||
          this.selfOfficeType == 'App\\Models\\Department';
      });

    AuthorityCacheApi.getWithParse().then((authorityList) => {
      if (this.isCreating) {
        this.isAuthority = !!authorityList['octlink.vehicle.create'];
      } else {
        this.isAuthority = !!authorityList['octlink.vehicle.update'];
      }
      if (!authorityList['octlink.vehicle.read']) {
        this.$router.push('/');
      }
    });
    const { loginWithRedirect } = useAuth();
    this.loginWithRedirect = loginWithRedirect;
    this.getVehicleForm();
  },
  computed: {
    operationOffices() {
      if (this.form.operationOffice) return [this.form.operationOffice]
      return []
    },
    managementOfficeClearable() {
      return !this.isSubManagementOffice && !this.isOperationOffice;
    },
    operationOfficeClearable() {
      return !this.isOperationOffice;
    },
  },
  methods: {
    submit() {
      this.running = true;
      const params = this.makeParams();

      Api()
        .createOrUpdateVehicle(this.$route.params.id, params)
        .then(() => {
          this.isCreating;
          this.showFlash(i18n.global.t('pages.common.messages.success'), 'success', true);
          this.running = false;
          this.$router.push('/vehicles');
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.errors = res.data.errors;
              scrollToErrorComponent();
          }
          this.running = false;
        });
    },
    makeParams() {
      const {
        operationOffice,
        managementOffice,
        digital_tacograph,
        digital_tacograph_FW_copy,
        department,
      } = this.form;

      let preparedFormData = {
        ...JSON.parse(JSON.stringify(this.form)),
        operation_office_id: operationOffice?.id,
        management_officeable_id: managementOffice?.id,
        management_officeable_type: managementOffice?.type,
        sim_type: this.simType,
        access_point: this.changeAccessPointToUpperCamelCase(this.accessPoint),
        config_hash: this.configHash,
        digital_tacograph: digital_tacograph?.id ?? digital_tacograph ?? '',
        department_id: department?.id ?? '',
      }

      if (operationOffice?.tdsensor === false) {
        preparedFormData.parameters.tdsensor.tdrfsensor = 'OFF';
      }

      if (digital_tacograph_FW_copy) {
        preparedFormData.digital_tacograph_FW_copy = digital_tacograph_FW_copy.id;
      }

      preparedFormData = deleteParams(preparedFormData, this.deletekeys);
      Object.keys(preparedFormData['parameters']['speed_alert']).forEach((key) => {
        if (typeof preparedFormData['parameters']['speed_alert'][key] === 'number') {
          preparedFormData['parameters']['speed_alert'][key] = preparedFormData['parameters']['speed_alert'][key].toString();
        }
      });

      let formData = new FormData();
      if (this.vehicle_verify_image[0]) {
        formData.append('img', this.vehicle_verify_image[0]);
      }

      ObjectToFormData(preparedFormData, formData, '');

      const machine_id = digital_tacograph?.name ?? '';
      formData.set('parameters[common][machine_id]', machine_id);

      return formData;
    },
    async getItem() {
      if (!this.$route.params.id) {
        this.loaded = true;
        this.showableUpdateButton = true;
        this.switchContentLoading(false);
        return;
      }
      await Api()
        .getVehicle(this.$route.params.id)
        .then((data) => {
          if (!data.id) {
            this.loaded = true;
            return this.$router.push('/vehicles/form');
          }
          this.form = data;
          if (this.form.parameters.device)
            this.form.parameters.device.mqtt_cid = '';

          this.form['operationOffice'] = {
            id: data['operation_office_id'],
          };
          this.form.managementOffice = {
            id: data['management_officeable_id'],
            type: data['management_officeable_type'],
          };
          this.initialDepartmentId = data['department_id'];
          this.initialModel = JSON.parse(JSON.stringify(this.form));
          this.initialManagementId = data['management_officeable_id'];
          this.initialManagementType = data['management_officeable_type'];
          this.initialOperationId = this.form['operationOffice']['id'];
          this.initialDigitacoMappingId = this.form['digital_tacograph'];
          this.initialDigitalTacographFWCopyId =
            data['digital_tacograph_FW_copy'];
          this.digitalTacographFWCopyId = this.form.digital_tacograph_FW_copy;
          this.setSimAndAccessPoint(this.form);
          this.configHash = this.form['config_hash'];
          this.isLatestStatus = this.compareHash(this.configHash);
        });
      this.loaded = true;
      this.showableUpdateButton = true;
      this.switchContentLoading(false);
    },
    getVehicleForm() {
      Api()
        .getVehicleForm()
        .then((data) => {
          this.vehicleForm = data;
          this.getItem();
        });
    },
    changeAccessPointToSpace(accessPoint) {
      switch (accessPoint) {
        case 'BridgeDocomap':
          return 'Bridge docomap';
        case 'IoTCore':
          return 'IoT Core';
        case 'IoTGW':
          return 'IoT GW';
        default:
          return accessPoint;
      }
    },
    changeAccessPointToUpperCamelCase(accessPoint) {
      switch (accessPoint) {
        case 'Bridge docomap':
          return 'BridgeDocomap';
        case 'IoT Core':
          return 'IoTCore';
        case 'IoT GW':
          return 'IoTGW';
        default:
          return accessPoint;
      }
    },
    setSimAndAccessPoint(data) {
      this.simType = data['sim'];
      this.accessPoint = this.changeAccessPointToSpace(data['access_point']);
    },
    compareHash(compareParams) {
      if (!this.simType || !this.accessPoint) return true;
      const simAndAccessPoint = `${
        this.simType
      }.${this.changeAccessPointToUpperCamelCase(this.accessPoint)}`;
      const latestConfigHash =
        this.vehicleForm?.default_params[simAndAccessPoint]['config_hash'];
      return latestConfigHash == compareParams;
    },
    changeSimType(val) {
      if (this.simType != val) this.isLatestStatus = true;
      this.simType = val;
    },
    changeAccessPoint(val) {
      if (this.accessPoint != val) this.isLatestStatus = true;
      this.accessPoint = val;
    },
    changeIsLatestStatus(bool) {
      this.isLatestStatus = bool;
    },
    updateConfigHash(val) {
      if (this.isLatestStatus) this.configHash = val;
    },
  },
  watch: {
    'form.digital_tacograph_FW_copy': {
      handler() {
        const id =
          this.form?.digital_tacograph_FW_copy?.id ??
          this.form?.digital_tacograph_FW_copy;
        if (id == this.digitalTacographFWCopyId) return;
        this.digitalTacographFWCopyId = id;
        if (!this.form.digital_tacograph_FW_copy?.vehicle) return;

        const beforeMqttCid = this.form.parameters.device.mqtt_cid;
        this.form.parameters =
          this.form.digital_tacograph_FW_copy.vehicle.parameters;
        this.form.parameters.device.mqtt_cid = beforeMqttCid;
        this.setSimAndAccessPoint(this.form.digital_tacograph_FW_copy.vehicle);
        this.configHash =
          this.form.digital_tacograph_FW_copy.vehicle.config_hash;
        this.isLatestStatus = this.compareHash(this.configHash);
      },
      deep: true,
    },
    'form.digital_tacograph': {
      handler() {
        if (!this.form.parameters.common) return;
        this.form.parameters.common.machine_id =
          this.form.digital_tacograph?.name;
      },
      deep: true,
    },
  },
  beforeUnmount() {
    this.switchContentLoading(false);
  },
  components: {
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
    DepartmentAutoComplete,
    DigitacoMappingAutoComplete,
    DigitalTacographFWCopyAutoComplete,
    IniFileSetting,
    Thumbnail,
  },
};
</script>
