<template>
  <div class="daily-report-list">
    <template v-if="notReady"></template>
    <template v-else-if="!searched && !notReady && !isOperationOffice && !isDepartment">
      <div class="not-searched">
        <label >フィルターを実行してください</label>
      </div>
    </template>
    <template v-if="listLoading">
      <div class="not-searched">
        <label>読み込み中</label>
      </div>
    </template>
    <div class="table-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th class="p-1 pr-0">
              <div class="checkbox-in-grid align-middle">
                <v-checkbox class="d-block"
                  outlined hide-details="false"
                  v-model="allSelect"
                  @change="toggleSelectAll"
                />
              </div>
            </th>
            <th class="pl-0">Trip ID</th>
            <th>出庫日</th>
            <th>帰庫日</th>
            <th>車番</th>
            <th>ドライバー</th>
            <th>給油量</th>
            <th>詳細・給油入力</th>
            <th>最終更新者/日付</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
            <tr
              v-for="item in items"
              :key="item.delivery_plan_id"
            >
              <td class="p-1 pr-0">
                <v-checkbox
                  class="align-middle checkbox-in-grid"
                  label=''
                  v-model="selectStatuses[`${item.delivery_plan_id}`]"
                  @change="toggleSelect"
                />
              </td>
              <td class="pl-0">
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column justify-content-center">
                    <div class="d-flex">
                      {{ item.delivery_plan_id.substr(-7, 7) }}
                      <div style="width:10px;">
                        <span style="color:red;margin-left:3px;" v-if="item.has_editted">*</span>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-center">
                    <copy_element :copyText="item.delivery_plan_id" size="small" />
                  </div>
                </div>
              </td>
              <td>{{ datetimeFormat(item.leaving_warehouse_date_time) }}</td>
              <td>{{ datetimeFormat(item.returning_warehouse_date_time) }}</td>
              <td>{{ item.car_number }}</td>
              <td>
                {{ item.driver ? item.driver?.last_name + item.driver?.first_name : ''}}
                <span v-if="item.has_changed_driver" class="m-0" style="color: red;">*</span>
              </td>
              <td>{{ item.fuel_economy }}</td>
              <td>
                <update_modal
                  :driverId="item?.driver?.id"
                  :operationOffice="operationOffice"
                  :deliveryPlan="item"
                  @change="updateItem"
                  :showFlash="showFlash"
                  :createAuthority="createAuthority"
                  :updateAuthority="updateAuthority"
                  :createSpotAuthority="createSpotAuthority"
                />
              </td>
              <td>{{ item.latest_history }}</td>
            </tr>
          </template>
          <template v-else-if="searched && !listLoading">
            <tr>
              <td colspan="9">
                表にデータがありません。
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
import UpdateModal from "@/components/DailyReportUpdateModal"
import CopyElement from "@/components/CopyElement"
  export default {
    props: {
      searched: null,
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      showFlash: {
        type: Function
      },
      operationOffice: {
        type: Object
      },
      isOperationOffice: {
        type: Boolean
      },
      isDepartment: {
        type: Boolean
      },
      notReady: {
        type: Boolean
      },
      createAuthority: {
        type: Boolean
      },
      updateAuthority: {
        type: Boolean
      },
      createSpotAuthority: {
        type: Boolean
      },
    },
    data() {
      return {
        form: {},
        dailyReportList: [],
        selected: [],
        allSelect: false,
        selectStatuses: {},
      }
    },
    computed: {
    },
    methods: {
      toggleSelectAll() {
        Object.keys(this.selectStatuses).forEach((key) => {
          this.selectStatuses[key] = this.allSelect
        })
        this.onChange()
      },
      toggleSelect() {
        this.allSelect = false
        this.onChange()
      },
      onChange() {
        const selected = []
        Object.keys(this.selectStatuses).forEach((key) => {
          if (this.selectStatuses[key]) {
            const item = this.items.find(item => item.delivery_plan_id == key)
            selected.push(item)
          }
        })
        this.$emit('select', selected)
      },
      updateItem(item) {
        this.$emit('changeItem')
      },
      datetimeFormat(datetime) {
        const d = new Date(datetime)
        const year = d.getFullYear()
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const hour = d.getHours().toString().padStart(2, '0')
        const minutes = d.getMinutes().toString().padStart(2, '0')
        return `${year}-${month}-${day} ${hour}:${minutes}`
      },
    },
    watch: {
      items: {
        handler() {
          this.allSelect = false;
          this.selectStatuses = {}
          this.items.map((v) => {
            this.selectStatuses[v['delivery_plan_id']] = false
          })
        }
      },
    },
    components: {
      update_modal: UpdateModal,
      copy_element: CopyElement,
    }
  }

</script>

<style>
.daily-report-list .v-table__wrapper {
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
</style>
<style scoped>
.daily-report-list {
  position: relative;
}
.daily-report-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  min-width: 7em;
}
.daily-report-list table .table-header-row th {
  z-index: 1;
}
.daily-report-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid :first-child {
  height: inherit !important;
}
.checkbox-in-grid {
  display: inline-grid !important;
  transform: scale(1.2);
}
</style>
