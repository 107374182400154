<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex justify-content-between mb-1">
        <div class="d-flex">
          <h4 class="card-title mt-0">
            {{ i18n.global.t('pages.vehicles.title.list') }}
          </h4>
        </div>
        <div class="ml-2 mb-0">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area">
          <v-btn
            v-if="createAuthority"
            class="exec-btn"
            @click="moveFormView()"
          >
            {{ i18n.global.t('pages.common.button.newCreate') }}
          </v-btn>
          <FileDownloadBtn
            :showable="csvtAuthorities.csvExport"
            btnClass="exec-btn mt-0 ml-1"
            :btnName="i18n.global.t('pages.common.button.csvExport')"
            :type="downloadType"
            :filterItems="makeSearchParams()"
          />
          <v-btn
            v-show="csvtAuthorities.csvImport && (isManagementOffice || isSubManagementOffice)"
            class="exec-btn mt-0 ml-1"
            @click="activateModalState"
          >
            {{ $t("pages.common.button.csvImport") }}
          </v-btn>
        </div>
        <UpsertCsvButtons
          v-if="csvtAuthorities.csvImport || csvtAuthorities.csvExport"
          :title="$t('pages.common.button.csvUpsert')"
          :importTitle="$t('pages.vehicles.csv.importTitle')"
          :importType="importType"
          :exportType="exportType"
          :filterItems="makeSearchParams()"
          :getList="getVehicles"
          :authorities="csvtAuthorities"
          elementId="vehicles-upsert"
        />
      </div>
      <div>
        <VehicleList
          :searched="searched"
          :items="vehicleList"
          :listLoading="listLoading"
          :updatableStatusButton="changeStatusAuthority"
          @changeStatus="updateStatus"
          @changeItem="searchVehicleList"
          :showFlash="showFlash"
          v-model="sortParam"
          :isStatusUpdating="isStatusUpdating"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
    </div>

    <VehicleSearchForm
      :loading="searchLoading"
      :isSubManagementOffice="isSubManagementOffice"
      :isManagementOffice="isManagementOffice"
      :isDepartment="isDepartment"
      @formchange="handleChangeSearchForm"
      @search="searchVehicleList"
    />

    <FileUploadModal
      :modalState="modalState"
      :title="$t('pages.vehicles.csv.importTitle')"
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="vehicles"
      @closeModal="inactivateModalState"
    />
  </div>
</template>

<script>
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE, DEPARTMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import VehicleSearchForm from "@/components/VehicleParts/VehicleSearchForm"
  import VehicleList from "@/components/VehicleParts/VehicleList"
  import FileDownloadBtn from "@/components/Files/DownloadButton"
  import FileUploadModal from "@/components/Files/UploadModal"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import UpsertCsvButtons from "@/components/Common/UpsertCsvButtons"
  import i18n from '@/lang/i18n';

  export default {
    props: {
      showFlash: {
        type: Function
      }
    },
    data() {
      return {
        form: {},
        currentPage: 1,
        totalPage: 0,
        vehicleList: [],
        selectedDeliveryPlanIds: [],
        selectOutputOptions: {},
        searchLoading: false,
        listLoading: false,
        searched: false,
        beforeSearchParams: null,
        createAuthority: false,
        changeStatusAuthority: false,
        loginWithRedirect: null,
        sortParam: {},
        downloadType: "App\\Services\\Csv\\Exports\\VehicleService",
        uploadType: "App\\Services\\Csv\\Imports\\VehicleService",
        importType: "App\\Services\\Csv\\Upserts\\Imports\\VehicleService",
        exportType: "App\\Services\\Csv\\Upserts\\Exports\\VehicleService",
        modalState: false,
        pageName: "manageVehicle",
        isSubManagementOffice: false,
        isManagementOffice: false,
        csvtAuthorities: {
          csvImport: false,
          csvExport: false
        },
        isStatusUpdating: false
      }
    },
    computed: {
      borderWidth() {
        return '1000';
      }
    },
    setup() {
      document.title = i18n.global.t('pages.vehicles.title.list')
      return {
        i18n
      }
    },
    mounted() {
      this.getAuthorities()
      const { loginWithRedirect } = useAuth()
      this.loginWithRedirect = loginWithRedirect
      Api().getSelfAccount().then((data) => {
        this.isSubManagementOffice = data.login_organization_type == SUB_MANAGEMENT_OFFICE_TYPE
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
      })
      this.getVehicles()
    },
    watch: {
      currentPage: {
        handler() {
          this.getVehicles(this.currentPage, false)
        }
      },
      sortParam: {
        handler() {
          this.getVehicles(1)
        }
      },
    },
    methods: {
      async getAuthorities(isError = false) {
        if (isError) await AuthorityCacheApi.reflesh()
        const authorityList = await AuthorityCacheApi.getWithParse()
        this.createAuthority = !!authorityList["octlink.vehicle.create"]
        this.changeStatusAuthority = !!authorityList["octlink.vehicle.delete"];
        this.csvtAuthorities.csvImport = !!authorityList["octlink.vehicle.input"]
        this.csvtAuthorities.csvExport = !!authorityList["octlink.vehicle.output"]
      },
      handleChangeSearchForm(form) {
        this.form = form
      },
      searchVehicleList() {
        this.searchLoading = true
        this.getVehicles()
      },
      moveFormView() {
        this.$router.push('/vehicles/form')
      },
      getVehicles(page = 1, updateOption = true) {
        this.listLoading = true
        let req
        if (updateOption || !this.beforeSearchParams) {
          req = this.makeSearchParams(page)
          this.beforeSearchParams = req
        } else {
          req = this.beforeSearchParams
          req.page = page
        }

        Api().searchVehicles(req).then((data) => {
          this.vehicleList = data.items
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.searchLoading = false
          this.listLoading = false
          this.searched = true
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
              this.showFlash(i18n.global.t('pages.vehicles.errors.getList'), "error", false)
          }
          this.listLoading = false
          this.searchLoading = false
        })
      },
      makeSearchParams(page = undefined){
        const ret = {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_officeable_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          department_id: this.form?.department?.id,
          frames_number: this.form?.frames_number,
          registration_number: this.form?.registration_number,
          number_plate: this.form?.number_plate,
          digitaco_model_number: this.form?.model_number?.id,
          device_id: this.form?.device?.id,
          fw_version: this.form?.fw_version,
          sort: this.sortParam?.sort,
          sort_type: this.sortParam?.type,
          status: this.form?.status
        }
        return ret
      },
      convertDateTimeTodate(datetime) {
        const ret = datetime?.toISOString() || ''
        return ret.split('T')[0].replaceAll('-', '/')
      },
      inactivateModalState(isReacquireVehicle) {
        this.modalState = false;
        if (isReacquireVehicle) this.getVehicles();
      },
      activateModalState() {
        this.modalState = true;
      },
      updateStatus(params, status) {
        this.isStatusUpdating = true;
        Api().updateVehicleStatus(params, status).then((_data) => {
          this.showFlash(this.$t("pages.common.messages.changeStatusSuccess"), "success");
          this.isStatusUpdating = false;
          this.getVehicles();
        }).catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              this.showFlash(this.$t("pages.common.messages.forbidden"), "error", false)
              this.getAuthorities(true);
              break;
            case 422:
              this.showFlash(
                this.$t("pages.common.messages.changeStatusFail"),
                "error",
                false
              );
          }
          this.isStatusUpdating = false;
        });
      },
    },
    components: {
      VehicleSearchForm,
      VehicleList,
      pagenate: PagenateItems,
      FileDownloadBtn,
      FileUploadModal,
      ManualLink,
      UpsertCsvButtons,
    }
  }
</script>

<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}

</style>
