<template>
  <div>
    <div class="vehicle-list">
      <template v-if="!searched && !listLoading">
        <div class="not-searched">
          <label >{{ i18n.global.t('pages.common.list.runFilter') }}</label>
        </div>
      </template>
      <template v-if="listLoading">
        <div class="not-searched">
          <label>{{ i18n.global.t('pages.common.list.reading') }}</label>
        </div>
      </template>
      <div class="list list-scrollbar">
        <v-table>
          <thead>
            <tr>
              <th class="id-column"><SortableHeader label="ID" name="id" v-model="sortParam" /></th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.managementOffice')"
                  name="management_office"
                  v-model="sortParam"
                />
              </th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.operationOffice')"
                  name="operation_office"
                  v-model="sortParam"
                />
              </th>
              <th>{{ i18n.global.t('pages.vehicles.framesNumber') }}</th>
              <th>{{ i18n.global.t('pages.vehicles.registrationNumber') }}</th>
              <th>{{ i18n.global.t('pages.vehicles.numberPlate') }}</th>
              <th>{{ i18n.global.t('pages.vehicles.digitacoModelNumber') }}</th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.department')"
                  name="department_target_name"
                  v-model="sortParam"
                  />
              </th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.deviceId')"
                  name="device_id"
                  v-model="sortParam"
                  />
              </th>
              <th>{{ i18n.global.t('pages.vehicles.fwVersion') }}</th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.status')"
                  name="status"
                  v-model="sortParam"
                />
              </th>
              <th class="updated-at-column">
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.updatedAt')"
                  name="updated_at"
                  v-model="sortParam"
                />
              </th>
              <th>
                <SortableHeader
                  :label="i18n.global.t('pages.vehicles.updatedBy')"
                  name="updated_by"
                  v-model="sortParam"
                />
              </th>
              <th>{{ i18n.global.t('pages.vehicles.edit') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="items?.length != 0">
            <tr
              v-for="item in items"
              :key="item.id"
            >
              <td>{{item.id}}</td>
              <td>{{item.management_officeable.company_name}}</td>
              <td>{{item.operation_office.operation_office_name}}</td>
              <td>{{item.frames_number}}</td>
              <td>{{item.registration_number}}</td>
              <td>{{item.number_plate}}</td>
              <td>{{item.device?.model_number}}</td>
              <td>{{item.department?.name}}</td>
              <td>{{item.device?.device_id}}</td>
              <td>{{item.fw_version_name}}</td>
              <td>
                <status_change_confirm_modal
                  :item="item"
                  @submit="emitChangeStatus"
                  :updatableStatusButton="updatableStatusButton"
                  :isStatusUpdating="isStatusUpdating"
                />
              </td>
              <td>{{datetimeFormat(item.updated_at)}}</td>
              <td>{{item.updated_by ? item.updated_by?.last_name + item.updated_by?.first_name : ''}}</td>
              <td>
                <EditLinkIcon :name="'VehicleForm'" :id="item.id" />
              </td>
            </tr>
            </template>
            <template v-else-if="searched && !listLoading">
              <tr>
                <td colspan="9">
                  {{ i18n.global.t('pages.common.list.noData') }}
                </td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import StatusChangeConfirmModal from "@/components/VehicleParts/StatusChangeConfirmModal"
import SortableHeader from "@/components/VehicleParts/SortableHeader"
import EditLinkIcon from "@/components/Common/EditLinkIcon";
import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      searched: {
        type: Boolean,
        default: false
      },
      updatableStatusButton: {
        type: Boolean
      },
      modelValue: null,
      isStatusUpdating: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        form: {},
        sortParam: {},
      }
    },
    mounted() {
      this.sortParam = this.modelValue
    },
    methods: {
      emitChangeStatus(params, status) {
        this.$emit('changeStatus', params, status)
      },
      datetimeFormat(datetime) {
        const d = new Date(datetime)
        const year = d.getFullYear()
        const month = (d.getMonth() + 1).toString().padStart(2, '0')
        const day = d.getDate().toString().padStart(2, '0')
        const hour = d.getHours().toString().padStart(2, '0')
        const minutes = d.getMinutes().toString().padStart(2, '0')
        return `${year}-${month}-${day} ${hour}:${minutes}`
      }
    },
    watch: {
      sortParam: {
        handler() {
          this.$emit('update:modelValue', this.sortParam)
        }
      },
    },
    components: {
      status_change_confirm_modal: StatusChangeConfirmModal,
      SortableHeader,
      EditLinkIcon
    }
  }

</script>
<style>
.vehicle-list .list table {
  position: absolute;
}
.vehicle-list .list td{
  word-break: keep-all;
}
.vehicle-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.vehicle-list {
  position: relative;
}
.vehicle-list .list{
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.vehicle-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.vehicle-list .list .id-column{
  min-width: 2em;
}
.vehicle-list .list .updated-at-column{
  min-width: 10em;;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.not-searched {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.not-searched>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
