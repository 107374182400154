<template>
  <div class="every-24-report-list">
    <template v-if="notReady"></template>
    <template v-if="!searched && !notReady && !isOperationOffice && !isDepartment">
      <div class="not-searched">
        <label >
          {{ i18n.global.t('pages.common.list.runFilter') }}
        </label>
      </div>
    </template>
    <template v-if="listLoading">
      <div class="not-searched">
        <label>{{ i18n.global.t('pages.common.list.reading') }}</label>
      </div>
    </template>
    <div class="table-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th>
              <div class="checkbox-in-grid align-middle">
              <v-checkbox
                outlined hide-details="false"
                v-model="allSelect"
                @change="toggleSelectAll"
              />
              </div>
            </th>
            <th>
              {{ i18n.global.t('pages.every24HoursReport.list.tripId') }}
            </th>
            <th>
              {{ i18n.global.t('pages.every24HoursReport.list.leaveDate') }}
            </th>
            <th>
              {{ i18n.global.t('pages.every24HoursReport.list.returnDate') }}
            </th>
            <th>
              {{ i18n.global.t('pages.every24HoursReport.list.vehicleNumber') }}
            </th>
            <th>
              {{ i18n.global.t('pages.every24HoursReport.list.driver') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
          <tr
            v-for="item in items"
            :key="item.delivery_plan_id"
          >
            <td>
              <v-checkbox
                class="align-middle checkbox-in-grid"
                label=''
                v-model="selectStatuses[`${item.delivery_plan_id}`]"
                @change="toggleSelect"
              />
            </td>
            <td>
              {{ item.delivery_plan_id.substr(-7, 7) }}
              <span v-if="item.has_editted" class="m-0" style="color: red;">*</span>
            </td>
            <td>{{ item.leaving_warehouse_date_time }}</td>
            <td>{{ item.returning_warehouse_date_time }}</td>
            <td>{{ item.car_number }}</td>
            <td>
              {{ item.driver ? item.driver?.last_name + item.driver?.first_name : ''}}
              <span v-if="item.has_changed_driver" class="m-0" style="color: red;">*</span>
            </td>
          </tr>
          </template>
          <template v-else-if="searched && !listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
  import i18n from "@/lang/i18n";

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      searched: null,
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      isOperationOffice: {
        type: Boolean
      },
      isDepartment: {
        type: Boolean
      },
      notReady: {
        type: Boolean
      },
    },
    data() {
      return {
        form: {},
        dailyReportList: [],
        selected: [],
        allSelect: false,
        selectStatuses: {},
      }
    },
    computed: {
    },
    methods: {
      toggleSelectAll() {
        Object.keys(this.selectStatuses).forEach((key) => {
          this.selectStatuses[key] = this.allSelect
        })
        this.onChange()
      },
      toggleSelect() {
        this.allSelect = false
        this.onChange()
      },
      onChange() {
        const selected = []
        Object.keys(this.selectStatuses).forEach((key) => {
          if (this.selectStatuses[key]) selected.push(key)
        })
        this.$emit('select', selected)
      },
    },
    watch: {
      items: {
        handler() {
          this.allSelect = false;
          this.selectStatuses = {}
          this.items.map((v) => {
            this.selectStatuses[v['delivery_plan_id']] = false
          })
        }
      },
    },
  }

</script>

<style>
.every-24-report-list .v-table__wrapper{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
</style>
<style scoped>
.every-24-report-list {
  position: relative;
}
.every-24-report-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  min-width: 7em;
}
.every-24-report-list table .table-header-row th {
  z-index: 1;
}
.every-24-report-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid :first-child {
  height: inherit !important;
}
.checkbox-in-grid {
  padding: 1vm;
  display: inline-grid !important;
  transform: scale(1.2);
}
</style>
