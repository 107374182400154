<template>
  <div :class="colClass" v-if="isShow">
    <v-text-field
      class="pt-2"
      v-model="value"
      :type="type"
      :min="min"
      :max="max"
      :disabled="readonly"
      :error-messages="errors"
      :title="title"
      @input="onInput"
      @blur="numberFormat"
      @keydown="preventInvalidNumber"
    >
      <template v-slot:label>
        {{ label }}
        <span v-if="isRequired" class="text-red">
          <strong>*</strong>
        </span>
      </template>
    </v-text-field>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    isDecimal: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max : {
      type: [String, Number],
    },
    modelValue: {
      type: [String, Number],
    },
    title: {
      type: String,
      default: '',
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    colClassNumber: {
      type: [Number, String],
      default: '6',
    }
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    colClass() {
      let colClassNumber = this.colClassNumber;
      if (typeof colClassNumber === 'number') {
        colClassNumber = String(colClassNumber);
      }
      return colClassNumber ? `col-${colClassNumber}` : 'col-6';
    },
  },
  methods: {
    onInput(event) {
      let inputValue = event.target.value;
      if (this.type === 'number') {
        if (!this.isDecimal) {
          inputValue = inputValue.replace(/[^0-9.]/g, '');
        }
        const numericValue = Number(inputValue);

        if (this.min !== undefined && numericValue < this.min) {
          inputValue = this.isDecimal ? Number(this.min).toFixed(this.step) : this.min;
        } else if (this.max !== undefined && numericValue > this.max) {
          inputValue = this.isDecimal ? Number(this.max).toFixed(this.step) : this.max;
        }
      }

      this.value = inputValue;
      this.$emit('update:modelValue', this.value);
    },
    preventInvalidNumber(event) {
      if (this.type === 'number' && ['e', 'E', '+', '-'].includes(event.key)) {
        event.preventDefault();
      }
    },
    numberFormat(event) {
      let inputValue = event.target.value;
      if (inputValue === "") {
        return;
      }

      const [integer, decimal] = inputValue.split('.');
      if (this.isDecimal && decimal === undefined) {
        inputValue = Number(integer).toFixed(this.step);
      } else if (this.isDecimal && decimal !== undefined && (decimal?.length > this.step)) {
        inputValue = `${integer}.${decimal.slice(0, this.step)}`;
      }

      this.value = inputValue;
      this.$emit('update:modelValue', this.value);
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.value = this.modelValue;
      },
      immediate: true,
    },
  },
};
</script>
