import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1185c20b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex",
  style: {"min-height":"45vw"}
}
const _hoisted_2 = { class: "content p-4 w-100" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "card-title mt-0" }
const _hoisted_5 = { class: "ml-auto ml-2 mb-1 align-self-end" }
const _hoisted_6 = { class: "mb-5 d-flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManualLink = _resolveComponent("ManualLink")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_firmware_list = _resolveComponent("firmware-list")!
  const _component_pagenate = _resolveComponent("pagenate")!
  const _component_firmware_list_search_form = _resolveComponent("firmware-list-search-form")!
  const _component_firmware_update_form = _resolveComponent("firmware-update-form")!
  const _component_GDialog = _resolveComponent("GDialog")!
  const _component_firmware_create_form = _resolveComponent("firmware-create-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.i18n.global.t('pages.firmwareList.title')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ManualLink, { pageName: _ctx.pageName }, null, 8, ["pageName"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          (_ctx.createAuthority)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "exec-btn",
                onClick: _ctx.showCreateFirmwareForm
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.i18n.global.t('pages.common.button.newCreate')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createVNode(_component_firmware_list, {
        firmwares: _ctx.firmwares,
        isFirmwareListLoading: _ctx.isFirmwareListLoading,
        onSortChange: _ctx.handleChangeSort,
        onUpdateFirmwareClick: _ctx.showUpdateFirmwareForm
      }, null, 8, ["firmwares", "isFirmwareListLoading", "onSortChange", "onUpdateFirmwareClick"]),
      _createVNode(_component_pagenate, {
        range: 2,
        totalPage: _ctx.totalPage,
        modelValue: _ctx.currentPage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event))
      }, null, 8, ["totalPage", "modelValue"])
    ]),
    _createVNode(_component_firmware_list_search_form, {
      isFirmwareListLoading: _ctx.isFirmwareListLoading,
      onSearch: _ctx.resetPaginationAndGetFirmwares
    }, null, 8, ["isFirmwareListLoading", "onSearch"]),
    _createVNode(_component_GDialog, {
      modelValue: _ctx.showFirmwareUpdateForm,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showFirmwareUpdateForm) = $event)),
      "max-width": "400",
      transition: "custom-from-bottom-transition"
    }, {
      default: _withCtx(() => [
        (_ctx.showFirmwareUpdateForm)
          ? (_openBlock(), _createBlock(_component_firmware_update_form, {
              key: 0,
              "loaded-firmware": _ctx.firmwareToUpdate,
              loading: _ctx.isFirmwareToUpdateLoading,
              updating: _ctx.isFirmwareUpdating,
              updateAuthority: _ctx.updateAuthority,
              onCloseModal: _ctx.hideUpdateFirmwareForm,
              onSendForm: _ctx.updateFirmwareAndCloseFormAndReloadFirmwareList
            }, null, 8, ["loaded-firmware", "loading", "updating", "updateAuthority", "onCloseModal", "onSendForm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_GDialog, {
      modelValue: _ctx.showFirmwareCreateForm,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showFirmwareCreateForm) = $event)),
      "max-width": "400",
      transition: "custom-from-bottom-transition"
    }, {
      default: _withCtx(() => [
        (_ctx.showFirmwareCreateForm)
          ? (_openBlock(), _createBlock(_component_firmware_create_form, {
              key: 0,
              creating: _ctx.isFirmwareCreating,
              onCloseModal: _ctx.hideCreateFirmwareForm,
              onSendForm: _ctx.createFirmwareAndCloseFormAndReloadFirmwareList
            }, null, 8, ["creating", "onCloseModal", "onSendForm"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}