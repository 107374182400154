<script setup>
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import SingleDeviceTypeAutoComplete from '@/components/AutoCompletes/SingleDeviceTypeAutoComplete';
import { reactive, ref, computed, watch } from 'vue';
import i18n from '@/lang/i18n';

const props = defineProps({
  loading: {},
  makers: [],
  isManagementOffice: {
    type: Boolean,
    default: false
  },
});
const emit = defineEmits(['search', 'formchange']);
const hide = ref(false);
const form = reactive({
  status: 'only-active',
});

const selectedDevice = ref(null);

const title = computed(() => {
  return hide.value ? '' : i18n.global.t('pages.common.filter.title');
});

const searchAreaClass = computed(() => {
  if (hide.value) return 'search-area-hide p-4';
  return 'search-area p-4';
});

function clearForm() {
  form.managementOffice = !props.isManagementOffice ? form.managementOffice : null;
  form.operationOffice = null;
  form.maker = null;
  form.model_number = null;
  form.fw_version = null;
  form.sim_id = null;
  form.imei = null;
  form.model_type = null;
  form.status = 'only-active';
}
function toggleSidebar() {
  hide.value = !hide.value;
}
const changeSearchButton = computed(() => {
  return props.loading ?
    i18n.global.t('pages.common.button.loading') :
    i18n.global.t('pages.common.button.exec');
});
function handleClickSearch() {
  emit('search', form);
}
</script>
<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!hide">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="form.managementOffice"
          selectFirstIfOnlyOne
          :clearable="props.isManagementOffice"
        />
        <SingleOperationOfficeAutoComplete
          v-model="form.operationOffice"
          :managementOffice="form.managementOffice"
          selectFirstIfOnlyOne
        />
        <v-select
          :label="i18n.global.t('pages.devices.maker')"
          v-model="form.maker"
          :items="props.makers"
        />
        <v-text-field
          :label="i18n.global.t('pages.devices.modelNumber')"
          v-model="form.model_number"
        />
        <v-text-field
          :label="i18n.global.t('pages.devices.fwVersion')"
          v-model="form.fw_version"
        />
        <v-text-field
          :label="i18n.global.t('pages.devices.simId')"
          v-model="form.sim_id"
        />
        <v-text-field
          :label="i18n.global.t('pages.devices.imei')"
          v-model="form.imei"
        />
        <SingleDeviceTypeAutoComplete v-model="form.model_type" />
        <div>
          <div style="font-size: 13px; color: gray; font-weight: bold">
            {{ i18n.global.t('pages.devices.status') }}
          </div>
          <v-radio-group
            v-model="form.status"
            class="radio-group-row"
          >
            <v-radio
              value="only-active"
              label="有効のみ"
              color="blue"
            ></v-radio>
            <v-radio
              value="all"
              label="全て"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons">
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :disabled="loading"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ changeSearchButton }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" :disabled="loading" @click="clearForm">
            {{ i18n.global.t('pages.common.button.reset') }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
