<template>
  <div class="d-flex justify-content-end m-3">
    <v-btn class="m-1 reset-btn" @click="cancel">
      {{ i18n.global.t('pages.common.button.close') }}
    </v-btn>
    <v-btn
      class="m-1 exec-btn"
      @click="submit"
      :loading="running"
      :disabled="hasItemError || hasError">
      {{ i18n.global.t('pages.common.button.conservation') }}
    </v-btn>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits, watch } from 'vue'
  import i18n from '@/lang/i18n';

  const props = defineProps([
    'hasError',
    'items',
    'running',
  ]);
  const emit = defineEmits(['submit', 'cancel']);

  const submit = () => {
    if (!props.hasError) {
      emit('submit', props.items)
    }
  };
  const cancel = () => {
    emit('cancel')
  };
</script>

<style>
.v-btn--disabled.v-btn--variant-contained {
  color:inherit;
  box-shadow: inherit;
  background: rgb(21, 130, 156) !important;
}
</style>
