export default {
  title: "日報レイアウト一覧",
  documentTitle: "日報レイアウト",
  name: "名称",
  errors: {
    getList: "一覧の取得に失敗しました。"
  },
  list: {
    id: "ID",
    note: "備考",
    updateAt: "最終更新日",
    updateBy: "最終更新者",
    edit: "編集",
    update: "更新"
  },
  modal: {
    label: {
      pageUnit: "ページ単位",
      allSelect: "チャート表示をすべて選択",
      chartDisplay: "チャート表示",
      memo: "メモ"
    },
    isNote: {
      yes: "有",
      none: "無し"
    },
    validate: {
      input: "入力してください"
    }
  }
}
