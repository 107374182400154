export default {
  title: {
    list: "車両管理",
    create: "車両作成",
    edit: "車両編集"
  },
  framesNumber: "車台番号",
  registrationNumber: "車両番号",
  numberPlate: "ナンバープレート",
  digitacoModelNumber: "デジタコ型番",
  fwVersion: "F/Wバージョン",
  status: "ステータス",
  id: "ID",
  managementOffice: "管理会社",
  operationOffice: "運営会社",
  department: "組織",
  deviceId: "デバイスID",
  updatedAt: "最終更新日",
  updatedBy: "最終更新者",
  edit: "編集",
  confirmationModal: {
    enable: "有効",
    disable: "無効",
    statusConfirm: "車両を{ status }にします。よろしいですか？",
    button: {
    update: "更新"
    }
  },
  csv: {
    importTitle: "車両の入力",
  },
  errors: {
    getList: "一覧の取得に失敗しました。"
  },
  messages: {
  },
  form: {
    vehicleVerifyImage: "車検証画像データ",
    vehicleInspectionInformation: "初年度登録",
    mileageMeter: "走行距離メータ",
    digitacoMapping: "デジタコマッピング",
    firmwareSettingCopy: "他のデジタコファームウェアから設定をコピー",
    sim: "SIM",
    accessPoint: "接続先 MQTT",
    iniFileSetting: {
      title: "INIファイル設定",
      messages: {
        update: "INIファイル設定を最新に更新する",
        noLatest: "INIファイル設定が最新ではありません。"
      },
      etc: {
        title: "ETC",
        connect: "ETC連動（ON/OFF）",
        device: "ETC車載器メーカー",
        roadSelect: "一般/高速自動切替（ON/OFF）"
      },
      tdSensor: {
        title: "TDSENSOR",
        ch: "チャンネル",
        ch1: "チャンネル１",
        ch2: "チャンネル２",
        ch3: "チャンネル３",
        ch4: "チャンネル４",
        tdrfSensor: "温度管理（ON/OFF）",
        chUpper: "チャンネル上限",
        chLower: "チャンネル下限"
      },
      gSensor: {
        title: "GSENSOR",
        acceleration: "加速度設定",
        accelerationForward: "急加速（速度差）",
        accelerationBackward: "急ブレーキ（速度差）",
        horizontal: "急旋回",
        backward: "急減速",
        forward: "急加速",
      },
      speedAlert: {
        title: "SPEED ALERT",
        subtitle: "スピードアラートの設定",
        continuous: "連続",
        fifteenSeconds: "15秒",
        thirtySeconds: "30秒",
        fortyFiveSeconds: "45秒",
        sixtySeconds: "60秒",
        onlyExcess: "超過時のみ (0)",
        continuation: "継続 (1)",
        speedLimitAlert: "速度超過アラート機能",
        alertSpeedSettings: "アラート速度設定",
        expresswaySpeed: "高速道路 { speedLimit } km/hの",
        expresswayKph: "高速道路 (km/h)",
        generalRoadSpeed: "一般道路 { speedLimit } km/hの",
        generalRoadKph: "一般道路 (km/h)",
        preAlertInKph: "km/hの手前 { speed } km/hで事前警告",
        decisionTime: "判定時間",
        decisionTimeSeconds: "判定時間 (秒)",
        alertTiming: "アラートタイミング",
        alertTimingLabel: "アラートタイミング (0=超過時のみ、1=継続)",
        alertInterval: "アラート間隔",
        speedLimitPreAlert: "速度超過事前アラート機能",
        PreAlertSpeedSettings: "事前アラート速度設定"
      },
      pulse: {
        title: "PULSE",
        speedPulse: "車速パルス係数",
        tachoPulse: "エンジン回転係数",
        speedLevel: "スピードレベル（0＝電圧、1＝電流）",
        tachoLevel: "エンジン回転レベル（0＝電圧、1＝電流）",
        speedThreshold: "速度パルスレベル",
        tachoThreshold: "回転パルスレベル",
        tachoLimitNormal: "一般道エンジン回転超過（rpm）",
        tachoLimitHigh: "高速道エンジン回転超過（rpm）"
      },
      common: {
        title: "COMMON",
        allAllow: "ALL_ALLOW = 全て上書き設定",
        allDeny: "ALL_DENY = 全て上書き禁止",
        normalDeny: "NORMAL_DENY = 常時録画上書き禁止",
        eventDeny: "EVENT_DENY = イベント録画上書き禁止",
        digitachoDeny: "DIGITACHO_DENY = デジタコデータ上書き禁止",
        ftpOn: "FTPON",
        ftpId: "FTPID",
        ftpPass: "FTPPASS",
        alertIdlingFirstSec: "アイドリング超過時間（秒）：0～9999秒",
        alertIdlingSecondSec: "アイドリング警告繰返時間（秒）：0～9999秒",
        volume: "音量：0～5",
        alertIdlingSound: "アイドリング警告アラート",
        gpsDr: "GPSデットレコニング",
        cameraOn: "CAMERA_ON",
        overwriteDeny: "OVERWRITE_DENY",
        wakeupUpdate: "WAKEUP_UPDATE",
        uploadTripData: "UPLOAD_TRIP_DATA",
        automaticCancellationOn: "作業ボタン自動解除設定",
        automaticCancellationSpeed: "作業ボタン自動解除条件設定(km/h)：1～20",
        automaticCancellationTime: "作業ボタン自動解除条件設定(何秒間継続) : 1~30",
        delayPoweroffOn: "デジタコ電源OFFタイマー設定",
        delayPoweroffTime: "エンジンOFF後の起動時間(分) : 1~30",
        delayPoweroffVoltage: "デジタコ強制終了バッテリー電圧(V) : 10.0~24.0",
        longDriveLabel: "長時間連続運転判定解除条件設定",
        longDriveInterruptionMode: "長時間運転解除モード (A=休憩を含む全ての運転離脱、B=休憩時間のみ)",
        longDriveInterruptionCounterReset: "長時間運転解除カウンタリセット (1=車速検知時、2=作業終了時)",
        selectLongDriveInterruptionModeA: "休憩を含む全ての運転離脱 (A)",
        selectLongDriveInterruptionModeB: "休憩時間のみ (B)",
        selectLongDriveCounterReset1: "車速検知時 (1)",
        selectLongDriveCounterReset2: "作業終了時 (2)",
      }
    }
  }
}
