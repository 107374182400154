import { routingAuthorityNames } from "@/services/consts";
import AccessChecker from "@/router/accessChecker";
import { getSiteLink } from "@/router/links";
import SelfOffice from "@/types/SelfOffice"
import i18n from '@/lang/i18n';

export type SidebarLink = {
  name: string,
  path: string,
  icon: string,
  showLink?: boolean,
  extarnal_link?: boolean,
  childLinks?: Array<SidebarLink>,
}

class SidebarLinksCreator {

  accessChecker: AccessChecker
  office: SelfOffice

  constructor(accessChecker: AccessChecker, office: SelfOffice) {
    this.accessChecker = accessChecker
    this.office = office
  }

  get getLinks() {
    return this.getVisibleLinks(this.getExistLinks())
  }

  getExistLinks(): Array<SidebarLink> {
    return [
      this.createSideBarLinkBySiteLink(
        "dashboard",
        i18n.global.t('sideMenue.dashboard'),
        "mdi-chart-line"
      ),
      {
        name: i18n.global.t('sideMenue.firmware.firmware'),
        path: "#",
        icon: "mdi-tools",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "firmwares",
            i18n.global.t('sideMenue.firmware.firmwareList'),
          ),
          this.createSideBarLinkBySiteLink(
            "FirmwareDevices",
            i18n.global.t('sideMenue.firmware.FirmwareUpdate'),
          ),
        ]
      },
      {
        name: i18n.global.t('sideMenue.dailyReport.dailyReport'),
        path: "#",
        icon: "mdi-note-text",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "DailyReport",
            i18n.global.t('sideMenue.dailyReport.dailyReportOutput'),
          ),
          this.createSideBarLinkBySiteLink(
            "DailyReportLayout",
            i18n.global.t('sideMenue.dailyReport.dailyReportLayout'),
          ),
          this.createSideBarLinkBySiteLink(
            "UnexpectedEventAndAlertStatuseList",
            i18n.global.t('sideMenue.dailyReport.UnexpectedEventAndAlertStatuseList'),
          ),
        ],
      },
      this.createSideBarLinkBySiteLink(
        "Every24hoursReport",
        i18n.global.t('sideMenue.every24HoursReport'),
        "mdi-hours-24"
      ),
      this.createSideBarLinkBySiteLink(
        "MonthlyReport",
        i18n.global.t('sideMenue.monthryReport'),
        "mdi-note-multiple"
      ),
      {
        name:  i18n.global.t('sideMenue.TemperatureSensor.title'),
        path: "#",
        icon: "mdi-thermometer",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "TemperatureSensorCharts",
            i18n.global.t('sideMenue.TemperatureSensor.TemperatureSensor'),
          ),
          this.createSideBarLinkBySiteLink(
            "TemperaturInformationNumerical",
            i18n.global.t('sideMenue.TemperatureSensor.TemperaturInformationNumerical'),
          ),
        ],
      },
      {
        name:  i18n.global.t('sideMenue.safeDrivingReport'),
        path: "#",
        icon: "mdi-alert",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "SafeDrivingReport",
            i18n.global.t('sideMenue.safeDrivingReport'),
          ),
        ],
      },
      {
        name: i18n.global.t('sideMenue.settings.title'),
        path: "#",
        icon: "mdi-cog",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "Accounts",
            i18n.global.t('sideMenue.settings.account'),
          ),
          this.createSideBarLinkBySiteLink(
            "TenkoAssociation",
            i18n.global.t('sideMenue.settings.tenkoAssociation'),
          ),
          this.createSideBarLinkBySiteLink(
            "Vehicle",
            i18n.global.t('sideMenue.settings.vehicle'),
          ),
          this.createSideBarLinkBySiteLink(
            "DeviceList",
            i18n.global.t('sideMenue.settings.device'),
          ),
          this.createSideBarLinkBySiteLink(
            "SubManagementOffices",
            i18n.global.t('sideMenue.settings.subManagementOffice'),
          ),
          this.createSideBarLinkBySiteLink(
            "OperationOfficeEditForm",
            i18n.global.t('sideMenue.settings.operationOffice'),
          ),
          this.createSideBarLinkBySiteLink(
            "OperationOfficeList",
            i18n.global.t('sideMenue.settings.operationOffice'),
          ),
          this.createSideBarLinkBySiteLink(
            "Spot",
            i18n.global.t('sideMenue.settings.spot'),
          ),
          this.createSideBarLinkBySiteLink(
            "SafeDrivingSetting",
            i18n.global.t('sideMenue.settings.drivingScore'),
          ),
          this.createSideBarLinkBySiteLink(
            "Groups",
            i18n.global.t('sideMenue.settings.group'),
          ),
          this.createSideBarLinkBySiteLink(
            "InstallationOffice",
            i18n.global.t('sideMenue.settings.installationOffice'),
          ),
          this.createSideBarLinkBySiteLink(
            "AllowAccessOffice",
            i18n.global.t('sideMenue.settings.allowAccessOffice'),
          ),
          this.createSideBarLinkBySiteLink(
            "Departments",
            i18n.global.t('sideMenue.settings.department'),
          ),
          this.createSideBarLinkBySiteLink(
            "UserRoles",
            i18n.global.t('sideMenue.settings.userRoles'),
          ),
          this.createSideBarLinkBySiteLink(
            "RoleAssaignment",
            i18n.global.t('sideMenue.settings.roleAssaignment'),
          ),
        ],
      },
      {
        name: i18n.global.t('sideMenue.downloadData.downloadData'),
        path: "#",
        icon: "mdi-cloud-download",
        childLinks: [
          this.createSideBarLinkBySiteLink(
            "DownloadData",
            i18n.global.t('sideMenue.downloadData.downloadDataList'),
          ),
          this.createSideBarLinkBySiteLink(
            "DownloadDataHistories",
            i18n.global.t('sideMenue.downloadData.downloadDataHistory'),
          ),
        ],
      },
      this.createSideBarLinkBySiteLink(
        "CvrConvert",
        i18n.global.t('sideMenue.cvr'),
        "mdi-video",
      ),
      this.createSideBarLinkBySiteLink(
        "DataLinkage",
        "データ連携",
        "mdi-share-variant",
      ),
      {
        name: i18n.global.t('sideMenue.trackingService'),
        path: this.getTrackingServiceLink(),
        icon: "mdi-map-marker",
        extarnal_link: true,
        showLink: this.hasUserAccessTo(routingAuthorityNames.TrackingService),
        childLinks: [],
      }
    ];
  }

  hasUserAccessTo(authorityKey: string): boolean {
    return this.accessChecker.hasUserAccessTo(authorityKey)
  }

  isItManagementOffice(): boolean {
    return this.accessChecker.isItManagementOffice()
  }

  getTrackingServiceLink() {
    let path = process.env.VUE_APP_DOCOMAP_URL ?? '';
    if (!this.hasUserAccessTo("octlink.fleet_tracking_service.read")) return path;

    if (this.office.tracking_service == 'octlink') {
      path = process.env.VUE_APP_FLEET_TRACKING_SERVICE_URL ?? '';
    }
    if (this.office.tracking_service == 'docomap') {
      path = process.env.VUE_APP_DOCOMAP_URL ?? '';
    }
    return path
  }

  getVisibleLinks(sidebarLinks: Array<SidebarLink>): Array<SidebarLink> {
    if (this.accessChecker.hasUserAdministratorAccess()) {
      return sidebarLinks;
    }
    return sidebarLinks.filter(link => {
      return link.showLink || link.childLinks?.find(childLink => childLink.showLink)
    }).map(link => {
      link.childLinks = link.childLinks?.filter(childLink => childLink.showLink)
      return link
    })
  }

  createSideBarLinkBySiteLink(
    name: string,
    title: string,
    icon?: string
  ): SidebarLink {

    const siteLink = getSiteLink(name)
    if (!siteLink) {
      throw new Error('Not found link');
    }

    return {
      name: title,
      icon: icon ?? '',
      path: "/" + siteLink.path,
      showLink: siteLink.accessCheckHandler(this.accessChecker),
      childLinks: []
    }
  }
}

export default SidebarLinksCreator
