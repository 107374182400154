<template>
  <div class="row mr-3 ml-3 v-theme--light">
    <div class="col-6">
      <v-select
        class="small mt-2"
        :label="i18n.global.t('pages.vehicles.form.sim')"
        v-model="simType"
        :items="simTypeList"
        :clearable="selfOfficeType != 'App\\Models\\OperationOffice'"
        :readonly="selfOfficeType == 'App\\Models\\OperationOffice'"
      />
    </div>
    <div class="col-6">
      <ValidationErrors :messages="errors?.access_point">
        <v-select
          class="small mt-2"
          :label="i18n.global.t('pages.vehicles.form.accessPoint')"
          v-model="accessPoint"
          :items="accessPointList"
          :clearable="selfOfficeType != 'App\\Models\\OperationOffice'"
          :readonly="selfOfficeType == 'App\\Models\\OperationOffice'"
          :error="errors?.access_point?.length ?? 0 > 0"
        />
      </ValidationErrors>
    </div>
  </div>
  <div
    class="row mx-3 py-0 d-flex justify-end"
    v-if="!isOperationOffice && !isLatestStatus"
  >
    <div class="col-3">
      <v-btn
        color="warning"
        class="mb-1"
        @click="updateSimAndAccessPoint(true)"
      >
        {{ i18n.global.t('pages.vehicles.form.iniFileSetting.messages.update') }}
      </v-btn>
    </div>
    <div class="d-flex justify-end" style="font-size: 12px; color: red">
      {{ i18n.global.t('pages.vehicles.form.iniFileSetting.messages.noLatest') }}
    </div>
  </div>
  <div class="row mr-3 ml-3 v-theme--light">
    <div class="col-2">
      <h5 class="card-title mt-0">
        {{ i18n.global.t('pages.vehicles.form.iniFileSetting.title') }}
      </h5>
      <v-btn
        v-if="initialModel?.digital_tacograph"
        color="success"
        @click="downloadIniFile"
      >
        {{ i18n.global.t('common.downloadBtn') }}
      </v-btn>
    </div>
    <div class="col-10">
      <Common
        v-model="form.common"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
        :selfOfficeType="selfOfficeType"
      />
      <DailyEarly
        v-model="form.daily_early"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <Device
        v-model="form.device"
        :selectedDevice="selectedDevice"
        :selectedOperationOffice="selectedOperationOffice"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <Event
        v-model="form.event"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <Record
        v-model="form.record"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <Pulse
        v-model="form.pulse"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <SpeedAlert
          v-model="form.speed_alert"
          :formInputs="formInputs"
          :selectDefault="!!simType && !!accessPoint"
          :errors="errors"
      />
      <GSensor
        v-model="form.gsensor"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      />
      <TdSensor
        :selectedOperationOffice="selectedOperationOffice"
        v-model="form.tdsensor"
        :formInputs="formInputs"
        :selectDefault="!!simType && !!accessPoint"
        :errors="errors"
      ></TdSensor>
      <Etc
        v-model="form.etc"
        :isOperationOffice="isOperationOffice"
        :errors="errors"
      ></Etc>
    </div>
  </div>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Common from "@/components/VehicleFormParts/IniFileCommonSetting";
import DailyEarly from "@/components/VehicleFormParts/IniFileDailyEarySetting";
import Device from "@/components/VehicleFormParts/IniFileDeviceSetting";
import Event from "@/components/VehicleFormParts/IniFileEventSetting";
import Record from "@/components/VehicleFormParts/IniFileRecordSetting";
import Pulse from "@/components/VehicleFormParts/IniFilePulseSetting";
import SpeedAlert from "@/components/VehicleFormParts/IniFileSpeedAlertSetting";
import GSensor from "@/components/VehicleFormParts/IniFileGSensorSetting";
import TdSensor from "@/components/VehicleFormParts/IniFileTdSensorSetting";
import Etc from "@/components/VehicleFormParts/IniFileEtcSetting";
import ValidationErrors from "@/components/Common/ValidationErrors";
import { deepMergeObject } from "@/services/functions";
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    selectDefault: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    initialModel: null,
    selectedDevice: null,
    selectedOperationOffice: null,
    errors: {},
    item: {},
    selfOfficeType: null,
    vehicleForm: null,
    selectedSimType: {
      type: String,
      default: null,
    },
    selectedAccesspoint: {
      type: String,
      default: null,
    },
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isLatestStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.speed_alert',
      form: {
        common: {
          gps_dr: '0',
          camera_on: 'OFF',
          overwrite_deny: 'ALL_ALLOW',
          automatic_cancellation_on: 'ON',
          automatic_cancellation_speed: '20',
          automatic_cancellation_time: '30',
          delay_poweroff_on: 'OFF',
          delay_poweroff_time: '10',
          delay_poweroff_voltage: '19.0',
          long_drive_interruption_mode: 'A',
          long_drive_interruption_counter_reset: '1',
        },
        event: {
          pretime: '12',
          posttime: '8',
        },
        record: {
          camr_on: 'OFF',
          camf_bitrate: 'LOW',
          camr_bitrate: 'LOW',
          camf_fps: '15',
          camr_fps: '15',
          sound_on: 'OFF',
          cami_on: 'OFF',
          cami_bitrate: 'LOW',
          cami_fps: '15',
        },
        pulse: {
          speed_threshold: '39',
          tacho_threshold: '39',
        },
        gsensor: {
          acceleration: '0',
        },
        tdsensor: {
          tdrfsensor: 'OFF',
          tdrfband: '0',
        },
        etc:{
          connect: 'ON',
          device: 'FURUNO',
          road_select: 'MANUAL',
        }
      },
      formInputs: {},
      simType: null,
      simTypeList: ['SB', 'IIJ', 'IoT'],
      simTypeKeyValueList: {
        SB: 'SB',
        IIJ: 'IIJ',
        IoT: 'IoT',
      },
      accessPoint: null,
      accessPointKeyValueList: {
        MQTT: 'MQTT',
        Bridge: 'Bridge',
        'Bridge docomap': 'BridgeDocomap',
        'IoT GW': 'IoTGW',
        'IoT Core': 'IoTCore',
      },
      simAndAccessPointCombinationList: {},
      currentParamJson: '',
      defaultParamJsons: {},
    };
  },
  mounted() {
    if (!this.$route.params.id) {
      this.simType = 'IIJ';
      this.accessPoint = 'Bridge docomap';
    }
    this.formInputs = this.vehicleForm;
    Object.keys(this.vehicleForm?.default_params).forEach((k) => {
      const key = k.split('.')[0];
      const value = k.split('.')[1];
      if (!this.simAndAccessPointCombinationList[key])
        this.simAndAccessPointCombinationList[key] = [];
      Object.keys(this.accessPointKeyValueList).forEach((_k) => {
        const _value = this.accessPointKeyValueList[_k];
        if (value == _value)
          this.simAndAccessPointCombinationList[key].push(_k);
      });
    });
    this.setDefaultCommon();
    this.setDefaultAccessPoint();
    this.setDefaultSpeedAlert();
    this.updateSimAndAccessPoint();
  },
  computed: {
    accessPointList() {
      return this.simAndAccessPointCombinationList[this.simType];
    },
  },
  methods: {
    updateSimAndAccessPoint(isClickIvent = false) {
      if (!this.accessPoint || !this.simType) return this.emitConfigHash();

      const key = `${this.simTypeKeyValueList[this.simType]}.${
        this.accessPointKeyValueList[this.accessPoint]
      }`;
      const defaultParams = this.formInputs.default_params[key] ?? [];
      this.form = deepMergeObject(this.form, defaultParams['parameters']);
      if (isClickIvent) this.$emit('changeIsLatestStatus', true);
      this.emitConfigHash(defaultParams['config_hash']);
    },
    emitConfigHash(configHash = null) {
      this.$emit('updateConfigHash', configHash);
    },
    downloadIniFile() {
      Api().getVehicleIniFile(this.$route.params.id);
    },
    selectSimAndAccessPoint() {
      this.simType = this.selectedSimType;
      this.accessPoint = this.selectedAccesspoint;
    },
    createDefaultParamJsons() {
      Object.keys(this.formInputs.default_params).forEach((key) => {
        const json = JSON.stringify(
          this.formInputs.default_params[key].parameters
        );
        this.defaultParamJsons[json] = key;
      });
    },
    setDefaultCommon() {
      if (!this.form?.common?.automatic_cancellation_on) {
        this.form['common'].automatic_cancellation_on = 'ON';
      }
      if (!this.form?.common?.automatic_cancellation_speed) {
        this.form['common'].automatic_cancellation_speed = '20';
      }
      if (!this.form?.common?.automatic_cancellation_time) {
        this.form['common'].automatic_cancellation_time = '30';
      }
      if (!this.form?.common?.delay_poweroff_on) {
        this.form['common'].delay_poweroff_on = 'OFF';
      }
      if (!this.form?.common?.delay_poweroff_time) {
        this.form['common'].delay_poweroff_time = '10';
      }
      if (!this.form?.common?.delay_poweroff_voltage) {
        this.form['common'].delay_poweroff_voltage = '19.0';
      }
      if (!this.form?.common?.long_drive_interruption_mode) {
        this.form['common'].long_drive_interruption_mode = 'A';
      }
      if (!this.form?.common?.long_drive_interruption_counter_reset) {
        this.form['common'].long_drive_interruption_counter_reset = '1';
      }
    },
    setDefaultSpeedAlert() {
      if (!this.form?.speed_alert) {
        this.form['speed_alert'] = {
          speed_limit_normal_on: 'ON',
          speed_limit_high_on: 'ON',
          speed_limit_normal: '60',
          speed_limit_high: '80',
          speed_limit_normal_timing: '0',
          speed_limit_high_timing: '0',
          speed_limit_normal_interval: '15',
          speed_limit_high_interval: '15',
          speed_limit_high_delay: '5',
          speed_limit_normal_delay: '5',
          speed_limit_normal_pre_on: 'OFF',
          speed_limit_high_pre_on: 'OFF',
          speed_limit_normal_pre_delay: '0',
          speed_limit_high_pre_delay: '0',
          speed_limit_normal_pre_threshold: '5',
          speed_limit_high_pre_threshold: '5',
          speed_limit_normal_pre_timing: '0',
          speed_limit_high_pre_timing: '0',
          speed_limit_normal_pre_interval: '15',
          speed_limit_high_pre_interval: '15',
        };
      }
    },
    setDefaultTdSensor() {
      if (!this.form?.tdsensor) {
        this.form['tdsensor'] = {
          tdrfsensor: 'OFF',
          tds1: 'OFF',
          tds2: 'OFF',
          tds3: 'OFF',
          tds4: 'OFF',
          tds5: 'OFF',
          tds6: 'OFF',
        };
      }
      if (!this.form.tdsensor?.tdrfband) {
        this.form.tdsensor['tdrfband'] = 0;
      }
      if (!this.form.tdsensor?.tdrfch) {
        this.form.tdsensor['tdrfch'] = 0
      }
    },
    setDefaultEtc() {
      if (!this.form?.etc) {
        this.form['etc'] = {
          connect: 'ON',
          device: 'FURUNO',
          road_select: 'MANUAL',
        }
      }
      if (!this.form.etc?.connect) {
        this.form.etc['connect'] = 'ON'
      }
      if (!this.form.etc?.device) {
        this.form.etc['device'] = 'FURUNO'
      }
    },
    setDefaultAccessPoint() {
      if (!this.$route.params.id && this.selectedOperationOffice) {
        if (this.selectedOperationOffice.tracking_service == 'docomap') {
          this.accessPoint = 'Bridge docomap';
        } else {
          this.accessPoint = 'Bridge';
        }
      }
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue
        this.setDefaultCommon()
        this.setDefaultSpeedAlert()
        this.setDefaultTdSensor()
        this.setDefaultEtc() 
      },
      deep: true,
    },
    initialModel: {
      handler() {
        this.form = this.modelValue
        this.selectSimAndAccessPoint()
        this.setDefaultCommon()
        this.setDefaultSpeedAlert()
        this.setDefaultTdSensor()
        this.setDefaultEtc() 
      },
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form);
      },
      deep: true,
    },
    accessPoint: {
      handler() {
        this.updateSimAndAccessPoint();
        this.$emit('changeAccessPoint', this.accessPoint);
      },
    },
    simType: {
      handler() {
        const accessPointList =
          this.simAndAccessPointCombinationList[this.simType] ?? [];
        if (!accessPointList.includes(this.accessPoint))
          this.accessPoint = null;
        this.updateSimAndAccessPoint();
        this.$emit('changeSimType', this.simType);
      },
    },
    selectedOperationOffice: {
      handler() {
        this.setDefaultAccessPoint();
      },
    },
    selectedSimType: {
      handler(val) {
        this.simType = val;
      },
    },
    selectedAccesspoint: {
      handler(val) {
        this.accessPoint = val;
      },
    },
  },
  components: {
    Common,
    DailyEarly,
    Device,
    Event,
    Record,
    Pulse,
    SpeedAlert,
    GSensor,
    TdSensor,
    Etc,
    ValidationErrors,
  },
};
</script>
