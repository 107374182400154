export default {
  managementOffice: '管理会社',
  operationOffice: '運営会社',
  name: '名称',
  radius: '範囲 (m)',
  telephoneNumber: '電話番号',
  address: '所在地',
  notice1: '※登録した地点は次運行から反映されます。',
  notice2: '  過去の運行には反映されません。',
}
