import i18n from '@/lang/i18n';

export const HEADERS = [
  i18n.global.t('pages.tenkoAssociation.list.header.id'),
  i18n.global.t('pages.tenkoAssociation.list.header.drivarName'),
  i18n.global.t('pages.tenkoAssociation.list.header.email'),
  i18n.global.t('pages.tenkoAssociation.list.header.operationOfficeName'),
  i18n.global.t('pages.tenkoAssociation.list.header.department'),
  i18n.global.t('pages.tenkoAssociation.list.header.driverId'),
  i18n.global.t('pages.tenkoAssociation.list.header.employeeCode'),
  i18n.global.t('pages.tenkoAssociation.list.header.updatedAt'),
  i18n.global.t('pages.tenkoAssociation.list.header.updatedBy'),
  i18n.global.t('pages.tenkoAssociation.list.header.edit'),
  i18n.global.t('pages.tenkoAssociation.list.header.delete')
];
