<template>
  <table class="w-100 delivery-plan-works-table">
    <thead>
      <tr>
        <th>No</th>
        <th>作業</th>
        <th>住所・拠点</th>
        <th>着時刻</th>
        <th>発時刻</th>
        <th>作業時間</th>
        <th>走行時間</th>
        <th>走行距離</th>
        <th>積載/給油</th>
        <th>編集</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in items" :key="'work-' + item.work+item.start_time" >
        <Row
          :index="index+1"
          :item="item"
          :isWorkEditing="isWorkEditing"
          :isWaitingForSave="isWaitingForSave"
          :deliveryPlan="deliveryPlan"
          :operationOffice="operationOffice"
          :updateAuthority="updateAuthority"
          :createSpotAuthority="createSpotAuthority"
          @removeRow="removeRow"
          @updateItem="updateItem"
          :showFlash="showFlash"
        />
      </template>
    </tbody>
  </table>
</template>

<script setup>
import Row from "@/components/DailyReportUpdateModalParts/DailyReportWorkListRow.vue";
import { ref, computed } from "vue";

const props = defineProps({
  deliveryPlan: {},
  modelValue: [],
  dialogState: {
    type: Boolean,
    default: false,
  },
  updateAuthority: {
    type: Boolean,
    default: true,
  },
  showFlash: {
    type: Function,
  },
  operationOffice: {
    type: Object,
  },
  createSpotAuthority: {
    type: Boolean,
  },
});

const emits = defineEmits([
  'update:modelValue'
]);

const hasError = ref(false);
const deleteItems = ref([]);

const isWorkEditing = computed(() => {
  return !!items.value.filter((item) => {
    return item.editing;
  })[0];
})

const isWaitingForSave = computed(() => {
  return !!items.value.filter((item) => {
    return item.waiting_for_save;
  })[0];
})

const items = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

function removeRow(item) {
  clearErrors()
  const target = items.value.filter((i) => {
    return item.start_time == i.start_time &&
      item.work == i.work;
  })[0]
  const index = items.value.indexOf(target)
  item.adding = false
  if(item.work == 'stopping'){
    deleteItems.value.push({
      start_time: item.start_time,
      end_time: item.end_time,
    })
  }
  items.value.splice(index, 1)
  emits('update:modelValue', items.value);
}

function clearErrors() {
  hasError.value = false
}

function updateItem(item) {
  const target = items.value.filter((i) => {
    return item.start_time == i.start_time &&
      item.work == i.work;
  })[0]
  const index = items.value.indexOf(target)
  items.value[index] = item;
  emits('update:modelValue', items.value);
}

</script>

<style>
</style>
