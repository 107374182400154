export default {
  title: "組織一覧",
  list: {
    header: {
      id: "ID",
      operationOffice: "運営会社名",
      firstLevel: "第1階層",
      secondLevel: "第2階層",
      updatedAt: "最終更新日",
      updatedBy: "最終更新者",
      form: "編集",
      delete: "削除",
    }
  },
  form: {
    name: "組織名",
    parent: "所属元",
    status: "ステータス",
  },
  createOrUpdateModal: {
    createTitle: "組織作成",
    updateTitle: "組織編集",
    operationOffice: "運営会社",
    department: "第1階層",
    idForCustomer: "組織ID",
    name: "組織名",
    level: "階層",
    selectLevel: "階層を選択",
    firstLevel: "第1階層",
    secondLevel: "第2階層",
  },
  deleteModal: {
    title: "組織削除",
    deleteMessage: "を削除しますか？",
    informationMessage: "に所属している組織が存在するため削除できません。",
  }
}
