<template>
  <template v-if="!!formInputs?.form?.pulse">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">
        {{ i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.title') }}
      </v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <template v-for="(title, key) in keys" :key="key">
            <Input
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes(key)"
                :errors="formFieldOptions[key].errors"
            />
          </template>
        </div>
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.pulse',
      keys: {
        'speed_pulse': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.speedPulse'),
        'tacho_pulse': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.tachoPulse'),
        'speed_level': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.speedLevel'),
        'tacho_level': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.tachoLevel'),
        'speed_threshold': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.speedThreshold'),
        'tacho_threshold': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.tachoThreshold'),
        'tacho_limit_normal': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.tachoLimitNormal'),
        'tacho_limit_high': i18n.global.t('pages.vehicles.form.iniFileSetting.pulse.tachoLimitHigh'),
      }
    }
  },
  methods: {
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    formFieldOptions() {
      const parentKey = 'pulse'

      const options = {
        machineId: this.getShowAndRequiredAndErrors(parentKey, ['machine_id']),
      };

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key]);
      });

      return options;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue
      }
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.pulse) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.pulse ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
  },
}
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>