<script setup>
import StatusChangeConfirmModal from "@/components/Common/StatusCangeConfirmModal"
import { formatDate } from "@/services/functions"
import { deviceTypes } from "@/services/consts"
import EditLinkIcon from "@/components/Common/EditLinkIcon"
import i18n from '@/lang/i18n';

const props = defineProps({
  selfRoleId: {
    type: Number,
    defaulf: 2
  },
  devices: [],
  changeStatusAuthority: {
    type: Boolean,
    default: false
  },
  listLoading: {
    type: Boolean,
    default: true
  },
  updatingStatus: {
    type: Boolean,
    default: false
  },
  modelValue: null,
})

const emit = defineEmits(['changeSortOrder', 'changeStatus'])
const header = [
  i18n.global.t('pages.devices.id'),
  i18n.global.t('pages.devices.maker'),
  i18n.global.t('pages.devices.modelNumber'),
  i18n.global.t('pages.devices.deviceId'),
  i18n.global.t('pages.devices.simId'),
  i18n.global.t('pages.devices.imei'),
  i18n.global.t('pages.devices.modelType'),
  i18n.global.t('pages.devices.fw'),
  i18n.global.t('pages.devices.managementOfficeName'),
  i18n.global.t('pages.devices.operationOfficeName'),
  i18n.global.t('pages.devices.installationCar'),
  i18n.global.t('pages.devices.status'),
  i18n.global.t('pages.devices.updatedAt'),
  i18n.global.t('pages.devices.updatedBy'),
  i18n.global.t('pages.devices.edit')
]
const sortableItems = {
  [i18n.global.t('pages.devices.id')]: 'id',
  [i18n.global.t('pages.devices.maker')]: 'maker',
  [i18n.global.t('pages.devices.modelNumber')]: 'model_number',
  [i18n.global.t('pages.devices.deviceId')]: 'device_id',
  [i18n.global.t('pages.devices.managementOfficeName')]: 'officeable_name',
  [i18n.global.t('pages.devices.operationOfficeName')]: 'operation_office',
  [i18n.global.t('pages.devices.installationCar')]: 'installation_car',
  [i18n.global.t('pages.devices.status')]: 'status',
  [i18n.global.t('pages.devices.updatedAt')]: 'updated_at',
  [i18n.global.t('pages.devices.updatedBy')]: 'updated_by'
}
const currentSort = { item: 'id', type: 'ASC' }
const sortTypes = ['ASC', 'DESC']

function datetimeFormat (datetime) {
  const d = new Date(datetime)
  return formatDate(d)
}

function firmwareVersion (installationVehicle) {
  return installationVehicle?.firmwares?.[0]?.version
}

function emitChangeSortOrder (sortItem, sortType = null) {
  if (!sortableItem(sortItem)) return
  const item = sortableItems[sortItem]
  if (sortType) {
    currentSort.type = sortType
  } else {
    if (currentSort.item == item) {
      const type = sortTypes.find(type => type != currentSort.type)
      currentSort.type = type
    } else {
      currentSort.type = sortTypes[0]
    }
  }
  currentSort.item = item
  emit('changeSortOrder', currentSort.item, currentSort.type)
}

function sortableItem (itemName) {
  return Object.keys(sortableItems).includes(itemName)
}

function addClassIfSortable (itemName) {
  if (!sortableItem(itemName)) return
  return 'sort-icon pl-2 pt-1';
}

function highLightIfCurrentSort (item, type) {
  if (currentSort.item != sortableItems[item] || currentSort.type != type) return
  return 'font-weight: bold;'
}

function applyLeftStyle (index) {
  if (index == 0) return 'left: 0;'
}

function emitChangeStatus (params, key) { emit('changeStatus', params, key) }

function modelType (model) {
  if (!model) return ''
  const modelType = deviceTypes.find(type => type.id == model)
  return modelType.type
}
</script>

<template>
  <div class="device-list">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>
          {{ i18n.global.t('pages.common.list.reading') }}
        </label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th v-for="(item, index) in header" :key="index" class="table-header column-space" :style="applyLeftStyle(index)">
              <div class="d-flex">
                <span v-show="sortableItem(item)" style="display: inline-block;">
                  <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                  <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
                </span>
                <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="devices?.length != 0">
            <tr v-for="device in devices" :key="device.id">
              <td class="column-space fix-td">{{ device.id }}</td>
              <td class="column-space">{{ device.maker.name }}</td>
              <td class="column-space">{{ device.model_number }}</td>
              <td class="column-space">{{ device.device_id }}</td>
              <td class="column-space">{{ device.sim_id }}</td>
              <td class="column-space">{{ device.imei }}</td>
              <td class="column-space">{{ modelType(device.model_type) }}</td>
              <td class="column-space">{{ firmwareVersion(device.installation_vehicle) }}</td>
              <td class="column-space">{{ device.management_officeable.company_name }}</td>
              <td class="column-space">{{ device.operation_office.operation_office_name }}</td>
              <td class="column-space">{{ device.installation_vehicle?.number_plate}}</td>
              <td class="column-space">
                <StatusChangeConfirmModal
                  :item="device"
                  :itemName="i18n.global.t('pages.devices.device')"
                  :updatableStatusButton="changeStatusAuthority"
                  :updatingStatus="updatingStatus"
                  @submit="emitChangeStatus"
                />
              </td>
              <td class="column-space">{{datetimeFormat(device.updated_at)}}</td>
              <td class="column-space">{{device.updated_by.last_name + ' ' + device.updated_by.first_name}}</td>
              <td v-show="selfRoleId == 1" class="column-space">
                <EditLinkIcon :name="'DeviceForm'" :id="device.id" />
              </td>
            </tr>
          </template>
          <template v-else-if="devices?.length == 0 && !listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>
<style>
.device-list .list table {
  position: absolute;
}
.device-list .list td {
  word-break: keep-all;
}
.device-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.device-list {
  position: relative;
}
.device-list .list {
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.device-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.device-list .list .id-column {
  min-width: 2em;
}
.device-list .list .updated-at-column {
  min-width: 10em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.list-loading {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.device-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
