export default {
  modelName: "sub_management_offices",
  title: {
    title: "準管理会社",
    listTitle: "準管理会社一覧",
    createTitle: "作成",
    editTitle: "編集",
  },
  id: "ID",
  companyName: "社名",
  dksNoInitial: "DKS-No頭文字",
  contactName: "担当者名",
  telephoneNumber: "電話番号",
  comment: "コメント",
  status: "ステータス",
  personInCharge: "管理会社担当者",
  updatedAt: "最終更新日",
  updatedBy: "最終更新者",
  list: {
    edit: "編集",
    existComment: "有",
    notExistComment: "無し",
    csvImportModalTitle: "準管理会社の入力",
    sortKey: {
      id: "ID",
      companyName: "社名",
      contactName: "担当者名",
      managementOffice: "管理会社",
    }
  },
  form: {
    companyNameJapan: "社名(カナ)",
    zipCode: "郵便番号",
    province: "都道府県",
    address1: "住所1",
    address2: "住所2",
    faxNumber: "ファックス",
    email: "メール",
    fileUpload: "アップロード",
    fileUpLoadLabel: "画像をアップロード",
  }
}
