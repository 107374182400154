<template>
  <div class="safe-driving-report-list">
    <template v-if="notReady"></template>
    <template v-else-if="!searched && !notReady && !isOperationOffice && !isDepartment">
      <div class="not-searched">
        <label >
          {{ i18n.global.t('pages.common.list.runFilter') }}
        </label>
      </div>
    </template>
    <template v-if="listLoading">
      <div class="not-searched">
        <label>
          {{ i18n.global.t('pages.common.list.reading') }}
        </label>
      </div>
    </template>
    <div class="table-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
              <th style="width: 20%">
                <div class="checkbox-in-grid align-middle">
                <v-checkbox
                  outlined hide-details="false"
                  v-model="allSelect"
                  @change="toggleSelectAll"
                />
                </div>
              </th>
              <th class="text-subtitle-1" style="font-weight: bold;">
                {{ i18n.global.t('pages.safeDrivingReports.list.driverName') }}
              </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>
              <v-checkbox
                class="align-middle checkbox-in-grid"
                label=''
                v-model="selectStatuses[`${item.id}`]"
                @change="toggleSelect"
              />
            </td>
            <td class="text-subtitle-2">{{item.last_name + ' ' + item.first_name}}</td>
          </tr>
          </template>
          <template v-else-if="searched && !listLoading">
            <tr>
              <td colspan="2">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      searched: null,
      items: [],
      listLoading: {
        type: Boolean,
        default: false
      },
      isOperationOffice: {
        type: Boolean
      },
      isDepartment: {
        type: Boolean
      },
      notReady: {
        type: Boolean
      },
    },
    data() {
      return {
        allSelect: false,
        selectStatuses: {},
      }
    },
    computed: {
    },
    methods: {
      toggleSelectAll() {
        Object.keys(this.selectStatuses).forEach((key) => {
          this.selectStatuses[key] = this.allSelect
        })
        this.onChange()
      },
      toggleSelect() {
        this.allSelect = false
        this.onChange()
      },
      onChange() {
        const selected = []
        this.items.forEach((item) => {
          if (this.selectStatuses[item.id]) { selected.push(item.driver_id) }
        })
        this.$emit('select', selected)
      },
    },
    watch: {
      items: {
        handler() {
          this.selectStatuses = {}
          this.items.map((v) => {
            this.selectStatuses[v['id']] = false
          })
          this.allSelect = false
          this.onChange()
        }
      },
    },
  }

</script>

<style>
.safe-driving-report-list .v-table__wrapper {
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
</style>
<style scoped>
.safe-driving-report-list {
  position: relative;
}
.safe-driving-report-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  min-width: 7em;
}
.safe-driving-report-list table .table-header-row th {
  z-index: 1;
}
.safe-driving-report-list table .table-header-row th:first-child {
  z-index: 2;
}
.checkbox-in-grid :first-child {
  height: inherit !important;
}
.checkbox-in-grid {
  padding: 1vm;
  display: inline-grid !important;
  transform: scale(1.2);
}
</style>
