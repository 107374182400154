<template>
  <div class="account-list">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>
          {{ i18n.global.t('pages.common.list.reading') }}
        </label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr class="table-header-row">
            <th v-for="(item, index) in header" :key="index" class="table-header column-space" :style="applyLeftStyle(index)">
              <div class="d-flex align-items-center">
                <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
                <span v-show="sortableItem(item)" style="display: inline-block;">
                  <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                  <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
                </span>
              </div>
            </th>
            <th v-if="isAdmin">
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="accounts?.length != 0">
            <template v-for="account in accounts" :key="account.id">
              <AccoutListRow
                :isAdmin="isAdmin"
                :account="account"
                :changeStatusAuthority="changeStatusAuthority"
                :listLoading="listLoading"
                :updatingStatus="updatingStatus"
                :deletable="deletable"
                @changeStatus="emitChangeStatus"
                :reload="reload"
                :showFlash="showFlash"
              />
            </template>
          </template>
          <template v-else-if="accounts?.length == 0 && !listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script>
  import AccoutListRow from "@/components/AccountParts/AccountListRow.vue"
  import { ADMIN_ROLE_TYPE } from "@/services/consts"
  import i18n from '@/lang/i18n';

  export default {
    setup() {
      return {
        i18n
      }
    },
    props: {
      selfRoleId: {
        type: Number,
        default: 2
      },
      accounts: [],
      changeStatusAuthority: {
        type: Boolean,
        default: false
      },
      listLoading: {
        type: Boolean,
        default: true
      },
      updatingStatus: {
        type: Boolean,
        default: false
      },
      deletable: {
        type: Boolean,
        default: false
      },
      showFlash: {
        type: Function
      },
      reload: {
        type: Function
      }
    },
    data() {
      return {
        header: [
          i18n.global.t('pages.accounts.id'),
          i18n.global.t('pages.accounts.loginId'),
          i18n.global.t('pages.accounts.accountName'),
          i18n.global.t('pages.accounts.email'),
          i18n.global.t('pages.accounts.officeableType'),
          i18n.global.t('pages.accounts.officeableName'),
          i18n.global.t('pages.accounts.department'),
          i18n.global.t('pages.accounts.role'),
          i18n.global.t('pages.accounts.driverId'),
          i18n.global.t('pages.accounts.telephoneNumber'),
          i18n.global.t('pages.accounts.status'),
          i18n.global.t('pages.accounts.updatedAt'),
          i18n.global.t('pages.accounts.updatedBy'),
        ],
        sortableItems: {
          [i18n.global.t('pages.accounts.id')]: 'id',
          [i18n.global.t('pages.accounts.loginId')]: 'login_id',
          [i18n.global.t('pages.accounts.accountName')]: 'account_name',
          [i18n.global.t('pages.accounts.email')]: 'email',
          [i18n.global.t('pages.accounts.officeableType')]: 'officeable_type',
          [i18n.global.t('pages.accounts.officeableName')]: 'officeable_name',
          [i18n.global.t('pages.accounts.department')]: 'department_target_name',
          [i18n.global.t('pages.accounts.status')]: 'status',
          [i18n.global.t('pages.accounts.updatedAt')]: 'updated_at',
          [i18n.global.t('pages.accounts.updatedBy')]: 'updated_by'
        },
        currentSort: { item: 'id', type: 'ASC' },
        sortTypes: ['ASC', 'DESC'],
        officeableTypeNames: {
          ManagementOffice: i18n.global.t('pages.accounts.managementOffice'),
          SubManagementOffice: i18n.global.t('pages.accounts.subManagementOffice'),
          OperationOffice: i18n.global.t('pages.accounts.operationOffice'),
          InstallationOffice: i18n.global.t('pages.accounts.installationOffice')
        },
        roles: {
          1: i18n.global.t('pages.accounts.administrator'),
          2: i18n.global.t('pages.accounts.driver')
        },
      }
    },
    methods: {
      emitChangeSortOrder(sortItem, sortType = null) {
        if (!this.sortableItem(sortItem)) return;

        const item = this.sortableItems[sortItem];
        if (sortType) {
          this.currentSort.type = sortType;
        } else {
          if (this.currentSort.item == item) {
            const type = this.sortTypes.find(type => type != this.currentSort.type);
            this.currentSort.type = type;
          } else {
            this.currentSort.type = this.sortTypes[0];
          }
        }
        this.currentSort.item = item;
        this.$emit('changeSortOrder', this.currentSort.item, this.currentSort.type);
      },
      sortableItem(itemName) {
        return Object.keys(this.sortableItems).includes(itemName);
      },
      addClassIfSortable(itemName) {
        if (!this.sortableItem(itemName)) return;

        return 'sort-icon pr-2';
      },
      highLightIfCurrentSort(item, type) {
        if (this.currentSort.item != this.sortableItems[item] || this.currentSort.type != type) return;

        return 'font-weight: bold;'
      },
      applyLeftStyle(index) {
        if (index == 0) return 'left: 0;';
      },
      emitChangeStatus(params) {
        this.$emit('changeStatus', params);
      }
    },
    computed: {
      isAdmin() {
        return this.selfRoleId == ADMIN_ROLE_TYPE;
      }
    },
    components: {
      AccoutListRow,
    }
  }
</script>

<style>
.account-list .list table {
  position: absolute;
}
.account-list .list td {
  word-break: keep-all;
}
.account-list .list th {
  min-width: 7em;
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  z-index: 1;
}
</style>
<style scoped>
.account-list {
  position: relative;
}
.account-list .list {
  position: relative;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
}
.account-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.account-list .list .id-column {
  min-width: 2em;
}
.account-list .list .updated-at-column {
  min-width: 10em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.list-loading {
  position: absolute;
  min-width: 58vw;
  max-height: 34em;
  min-height: 35vw;
  overflow: auto;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.account-list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>
