<script setup>
import MultiVideo from "@/components/CvrConvert/MultiVideo.vue";
import MultiVideoWithMeasuringChart from "@/components/CvrConvert/MultiVideoWithMeasuringChart.vue";
import { GDialog } from 'gitart-vue-dialog'
import { ref, defineProps, onMounted } from 'vue'
import i18n from '@/lang/i18n';

const props = defineProps(['item', 'showFlash']);
const item = ref(props.item);

const dialogState = ref(false);
const src = ref('');
const loading = ref(true);

const loadError = ref(false);
const errorMessage = ref('');

function openModal() {
  loading.value = true;
  dialogState.value = true;
}

function closeModal() {
  dialogState.value = false;
  errorMessage.value = '';
  loadError.value = false;
}

function addError(_errorMessage) {
  errorMessage.value = _errorMessage;
  loadError.value = true;
}
</script>


<template>
  <GDialog
    v-model="dialogState"
    ddmax-width="75%"
    transition="custom-from-bottom-transition"
  >
    <div class="v-theme--light">
      <div class="flex flex-wrap content-center py-3 mb-0 w-96 bg-danger opacity-75 w-full" v-if="loadError">
        <div class="w-full text-center text-light">
          {{errorMessage}}
        </div>
      </div>
    </div>
    <div v-if="dialogState">
      <MultiVideoWithMeasuringChart v-if="item.request_type == 'request'"
        :item="props.item"
        :addError="addError"
      />
      <MultiVideo v-else
        :item="props.item"
        :addError="addError"
      />
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-0 reset-btn" @click="closeModal">
        {{ i18n.global.t('pages.common.button.close') }}
      </v-btn>
    </div>
  </GDialog>
  <slot name="button" :dialogState="dialogState">
    <v-btn @click="openModal" >
      {{ i18n.global.t('pages.cvr_uploads.playBack') }}
    </v-btn>
  </slot>
</template>

<style scope>
.video-error-message {
  background-color: rgba(252,165,165);
}

.g-dialog-content {
  min-width: 50%;
  width: fit-content;
}
</style>
