<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex justify-content-between mb-1">
        <div class="text-h5 card-title mt-0">
          {{ i18n.global.t('pages.accounts.title.list') }}
        </div>
        <div class="ml-2 mb-0">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area">
          <v-btn v-show="createAuthority && selfRoleId == 1" class="exec-btn mt-0" @click="moveToAccountForm">
            {{ i18n.global.t('pages.common.button.newCreate') }}
          </v-btn>
          <FileDownloadBtn
            :showable="csvtAuthorities.csvExport"
            btnClass="exec-btn mt-0 ml-1"
            :btnName="i18n.global.t('pages.common.button.csvExport')"
            :type="downloadType"
            :filterItems="searchParams"
          />
          <v-btn
            v-show="csvtAuthorities.csvImport"
            class="exec-btn mt-0 ml-1"
            @click="activateModalState"
          >
            {{ i18n.global.t('pages.common.button.csvImport') }}
          </v-btn>
        </div>
        <UpsertCsvButtons
          v-if="csvtAuthorities.csvImport || csvtAuthorities.csvExport"
          :title="$t('pages.common.button.csvUpsert')"
          :importTitle="$t('pages.accounts.csv.importTitle')"
          :importType="importType"
          :exportType="exportType"
          :filterItems="searchParams"
          :getList="getAccounts"
          :authorities="csvtAuthorities"
          elementId="accounts-upsert"
        />
      </div>
      <div>
        <AccountList
          :selfRoleId="selfRoleId"
          :accounts="accounts"
          :deletable="deletable"
          :listLoading="listLoading"
          :changeStatusAuthority="changeStatusAuthority"
          :updatingStatus="updatingStatus"
          @changeStatus="updateStatus"
          @changeSortOrder="changeSortOrder"
          :showFlash="showFlash"
          :reload="getAccounts"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
    </div>

    <AccountsSearchForm
      :loading="listLoading"
      @search="getAccountsAfterSetParams"
    />

    <FileUploadModal
      :modalState="modalState"
      :title="i18n.global.t('pages.accounts.csv.importTitle')"
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="accounts"
      @closeModal="inactivateModalState"
    />
  </div>
</template>

<script>
  import AccountList from "@/components/AccountList"
  import AccountsSearchForm from "@/components/AccountsSearchForm"
  import FileDownloadBtn from "@/components/Files/DownloadButton"
  import FileUploadModal from "@/components/Files/UploadModal"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import UpsertCsvButtons from "@/components/Common/UpsertCsvButtons"
  import { MANAGEMENT_OFFICE_TYPE } from "@/services/consts"
  import i18n from '@/lang/i18n';

  export default {
    props: {
      showFlash: {
        type: Function
      }
    },
    data() {
      return {
        selfRoleId: 2,
        currentPage: 1,
        totalPage: 0,
        accounts: [],
        listLoading: false,
        searchParams: {
          page: 1,
          withs: 'updatedBy',
          status: 'only-active'
        },
        updatingStatus: false,
        creatingOrUpdating: false,
        editAccountId: null,
        modalFormText: null,
        errors: {},
        downloadType: "App\\Services\\Csv\\Exports\\AccountService",
        uploadType: "App\\Services\\Csv\\Imports\\AccountService",
        modalState: false,
        pageName: "manageAccount",
        officeAutocompleteStatus: false,
        operationOffice: {},
        operationOfficeId: null,
        isDisabledDownloadBtn: true,
        importType: "UpsertImportAccount",
        exportType: "UpsertExportAccount",
        createAuthority: false,
        changeStatusAuthority: false,
        csvtAuthorities: {
          csvImport: false,
          csvExport: false
        },
        isManagementOffice: false
      }
    },
    setup() {
      document.title = i18n.global.t('pages.accounts.title.list');
      const { loginWithRedirect } = useAuth();

      return {
        loginWithRedirect,
        i18n
      }
    },
    computed: {
      officeAutoCompleteHeight() {
        return this.officeAutocompleteStatus ? " min-height: 38vh;" : ""
      },
      deletable() {
        return this.changeStatusAuthority && this.isManagementOffice;
      }
    },
    watch: {
      currentPage: {
        handler() {
          this.searchParams.page = this.currentPage;
          this.getAccounts()
        }
      },
      operationOffice: {
        handler() {
          this.operationOfficeId = this.operationOffice?.id;
          this.isDisabledDownloadBtn = !this.operationOfficeId;
        }
      }
    },
    mounted() {
      this.listLoading = true;
      this.getAuthorities()
      Api().getSelfAccount().then((data) => {
        this.selfRoleId = data.role_id;
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.getAccounts();
      }).catch((error) => {
        const res = error.response
        if (res.status == 401) this.loginWithRedirect({ appState: { targetUrl: location.pathname } });
        this.listLoading = false;
      })
    },
    methods: {
      async getAuthorities(isError = false) {
        if (isError) await AuthorityCacheApi.reflesh()
        const authorityList = await AuthorityCacheApi.getWithParse();
        this.createAuthority = !!authorityList["octlink.user.create"];
        this.changeStatusAuthority = !!authorityList["octlink.user.delete"];
        this.csvtAuthorities.csvImport = !!authorityList["octlink.user.input"];
        this.csvtAuthorities.csvExport = !!authorityList["octlink.user.output"];
      },
      getAccounts() {
        this.listLoading = true;

        Api().getPaginatedAccounts(this.searchParams).then((data) => {
          this.accounts = data.accounts
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.listLoading = false
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
              this.showFlash(i18n.global.t('pages.accounts.messages.getListError'), "error", false)
          }
          this.listLoading = false;
        })
      },
      updateStatus(params) {
        this.updatingStatus = true;
        Api().updateAccountStatus(params).then((_data) => {
          this.showFlash(this.$t("pages.common.messages.changeStatusSuccess"), "success");
          this.updatingStatus = false;
          this.getAccounts();
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              this.showFlash(this.$t("pages.common.messages.forbidden"), "error", false)
              this.getAuthorities(true)
              break
            case 422:
              this.showFlash(this.$t("pages.common.messages.changeStatusFail"), "error", false)
          }
          this.updatingStatus = false;
        })
      },
      getAccountsAfterSetParams(searchParams) {
        searchParams.page = 1;
        searchParams.withs = this.searchParams.withs;
        searchParams.sort = this.searchParams.sort;
        searchParams.sort_type = this.searchParams.sort_type;
        this.searchParams = searchParams;
        this.getAccounts();
      },
      changeSortOrder(sortItem, sortType) {
        this.searchParams.sort = sortItem;
        this.searchParams.sort_type = sortType;
        if (this.currentPage == 1) {
          this.getAccounts();
        } else {
          this.currentPage = 1;
        }
      },
      moveToAccountForm() {
        this.$router.push('/accounts/form/');
      },
      inactivateModalState(isReacquireAccount, key = null) {
        this.modalState = false;
        if (isReacquireAccount) this.getAccounts();
      },
      activateModalState() {
        this.modalState = true;
      },
    },
    components: {
      AccountList,
      AccountsSearchForm,
      pagenate: PagenateItems,
      FileDownloadBtn,
      FileUploadModal,
      ManualLink,
      UpsertCsvButtons,
    }
  }
</script>
