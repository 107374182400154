<template>
  <GDialog
    v-model="dialogState"
    max-width="30%"
    transition="custom-from-bottom-transition"
  >
    <div class="modal-header justify-content-center pt-3">
      <label>
        {{ i18n.global.t('common.confirmationModal.title') }}
      </label>
    </div>
    <div class="row mr-3 ml-3 pl-5 v-theme--light">
      {{
        `${i18n.global.t('pages.accounts.confirmationModal.account')}
        ${nextStatus()}
        ${i18n.global.t('pages.accounts.confirmationModal.statusConfirm')}`
      }}
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-1 reset-btn" @click="closeModal" :disabled="updatingStatus">
        {{ i18n.global.t('pages.common.button.close') }}
      </v-btn>
      <v-btn class="m-1 exec-btn" @click="submit" :loading="updatingStatus">
        {{ i18n.global.t('pages.common.button.conservation') }}
      </v-btn>
    </div>
  </GDialog>

  <v-container
    class="px-0 py-2"
    fluid
    style="width: 3vw;"
  >
    <v-switch v-if="updatableStatusButton"
      v-model="currentStatus"
      @change="dialogState = true"
      color="success"
      hide-details
    ></v-switch>
    <v-switch v-if="!updatableStatusButton"
      v-model="currentStatus"
      @change="dialogState = true"
      color="success"
      :class="currentStatus ? 'text-success' : ''"
      disabled
      hide-details
    ></v-switch>
  </v-container>

</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import i18n from '@/lang/i18n';

export default {
  setup() {
      return {
        i18n
      }
    },
  props: {
    item: {
      default: null
    },
    updatableStatusButton: {
      type: Boolean,
    },
    updatingStatus: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogState: false,
    currentStatus: false,
  }),
  mounted() {
    this.currentStatus = !!this.item?.status
  },
  methods: {
    closeModal() {
      this.dialogState = false
    },
    submit() {
      const params = { id: this.item.id, status: this.item.status ? 0 : 1 };
      this.$emit('submit', params);
    },
    viewStatus() {
      return this.item?.status ?
        i18n.global.t('pages.accounts.confirmationModal.valid') :
        i18n.global.t('pages.accounts.confirmationModal.invalid')
    },
    nextStatus() {
      return this.item?.status ?
        i18n.global.t('pages.accounts.confirmationModal.invalid') :
        i18n.global.t('pages.accounts.confirmationModal.valid')
    },
  },
  watch: {
    item: {
      handler() {
        this.currentStatus = !!this.item?.status
      },
      deep: true,
    },
    dialogState: {
      handler() {
        this.currentStatus = !!this.item?.status
      },
    },
    updatingStatus: {
      handler(status) {
        if (status) return;

        this.closeModal();
      }
    }
  },
  components: {
    GDialog,
  },
}
</script>
<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }
  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}
.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }
  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *, .v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
