export default {
  title: {
    list: "デバイス一覧",
    create: "デバイス一覧/作成",
    edit: "デバイス一覧/編集"
  },
  id: "ID",
  maker: "メーカー",
  modelNumber:"製造番号",
  deviceId: "デバイスID",
  device: "デバイス",
  simId: "SIM ID",
  imei: "IMEI",
  modelType: "型番",
  fw: "F/W",
  fwVersion: "F/Wバージョン",
  managementOffice: "管理会社",
  operationOffice: "運営会社",
  managementOfficeName: "管理会社名",
  operationOfficeName: "運営会社名",
  installationCar: "設置車両",
  status: "ステータス",
  updatedAt: "最終更新日",
  updatedBy: "最終更新者",
  edit: "編集",
  csv: {
    importTitle: "デバイスの入力",
  }
}
