<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">
          {{ i18n.global.t('pages.dailyReportLayout.title') }}
        </h4>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area d-flex">
          <CreateModal v-if="authorityList['octlink.daily_report_layout.create']"
            :item="{}"
            @updateItem="searchList"
            :showFlash="showFlash"
          >
          </CreateModal>
        </div>
      </div>
      <div>
        <LayoutList
          :items="dataList"
          :listLoading="listLoading"
          @changeItem="searchList"
          :showFlash="showFlash"
          :authorityList="authorityList"
        />
        <Pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
      </div>
    </div>

    <SearchForm
      :loading="listLoading"
      @formchange="handleChangeSerarchForm"
      @search="searchList"
    />
  </div>
</template>

<script setup>
  import SearchForm from "@/components/DailyReportLayoutParts/SearchForm"
  import LayoutList from "@/components/DailyReportLayoutParts/LayoutList"
  import Api from "@/services/api/ApiServiceFabrick";
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import Pagenate from "@/components/PagenateItems"
  import CreateModal from "@/components/DailyReportLayoutParts/CreateModal"
  import { ref, defineProps, onMounted, watch } from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import ManualLink from "@/components/ManualLink"
  import i18n from '@/lang/i18n';

  const props = defineProps(['showFlash']);
  const showFlash = ref(props.showFlash);
  const listLoading = ref(false);
  const dataList = ref([]);
  const form = ref({});
  const currentPage = ref(1);
  const totalPage = ref(1);
  const authorityList = ref([]);
  const pageName = "dailyReportLayout";

  document.title = i18n.global.t('pages.dailyReportLayout.documentTitle');

  onMounted(() => {
    AuthorityCacheApi.getWithParse().then((data) => {
      authorityList.value = data;
    });
  })

  const handleChangeSerarchForm = (_form) => {
    form.value = _form
  }

  const searchList = (page = 1, updateOption = true) => {
    listLoading.value = true

    const req = makeSearchParams(page);

    Api().getDailyReportLayouts(req).then((res) => {
      dataList.value = res.data
      currentPage.value = res.current_page
      totalPage.value = res.total_page
      listLoading.value = false
    }).catch((error) => {
      const res = error?.response
      switch (res?.status) {
        case 401:
          useAuth.loginWithRedirect({ appState: { targetUrl: location.pathname } })
          break
        case 403:
          location.reload()
          break
        case 422:
          showFlash.value(i18n.global.t('pages.dailyReportLayout.errors.getList'), "error", false)
      }
      listLoading.value = false
    })
  }
  const makeSearchParams = (page) => {
    const ret = {
      page: page,
      name: form.value?.name,
    }
    return ret
  }

  watch(currentPage, () => {
    searchList(currentPage.value);
  });

  searchList();
</script>

<style scoped>
.content {
  min-width: 58vw;
}
</style>
<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
</style>
