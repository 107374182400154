<template>
  <div class="d-flex" style="min-height: 45vw">
    <div class="content p-4 w-100">
      <h4 class="card-title mt-0">{{ title }}</h4>
      <div class="row mr-3 v-theme--light">
        <div class="mb-4">
          <FormRow
            v-show="account.id && account.pwd_expiration"
            :title="i18n.global.t('pages.accounts.form.passwordExpiration')"
          >
            <div>{{ account.pwd_expiration }}</div>
          </FormRow>
          <AccountTextRow
            :title="i18n.global.t('pages.accounts.form.emailAddress')"
            :errors="errors['email'] ?? []"
            v-model="account.email"
            :generateButtonName="i18n.global.t('pages.accounts.form.automaticIssuanceEmailAddress')"
            :isGenerate="account.enabled_driver_mode"
            :disabled="account.no_email"
            :driverId="account.driver_id"
            :officeId="selectedOffice ? selectedOffice.id : null"
          />
          <ToggleRow
            :title="i18n.global.t('pages.accounts.form.NoEmailAddress')"
            v-model="account.no_email"
            :disabled="account.enabled_driver_mode"
          />
          <AccountTextRow
            v-show="account.no_email"
            :title="i18n.global.t('pages.accounts.form.tempEmailAddress')"
            :errors="errors['temporary_email'] ?? []"
            v-model="account.temporary_email"
            required="true"
          />
          <AccountTextRow
            v-show="account.no_email"
            :title="i18n.global.t('pages.accounts.loginId')"
            :disabled="account.enabled_driver_mode"
            :errors="errors['login_id'] ?? []"
            v-model="account.login_id"
            required="true"
          />
          <PasswordForm
            v-show="account.enabled_driver_mode ?? false"
            :title="i18n.global.t('pages.accounts.form.password')"
            :errors="errors['password'] ?? []"
            :accountId="accountId"
            :isDriverMode="account.enabled_driver_mode"
            :driverId="account.driver_id"
            v-model="account.password"
          />
          <ToggleRow
            v-show="isOperationOffice"
            :title="i18n.global.t('pages.accounts.form.driverMode')"
            v-model="account.enabled_driver_mode"
          />
          <ToggleRow
            v-show="isManagementOffice"
            :title="i18n.global.t('pages.accounts.form.operationVerificationAccount')"
            v-model="account.enabled_factory_setting_account"
          />
          <TextFields
            :title="i18n.global.t('pages.accounts.form.name')"
            :labels="[
              i18n.global.t('pages.accounts.form.lastName'),
              i18n.global.t('pages.accounts.form.firstName')
            ]"
            :errors="[errors['last_name'] ?? [], errors['first_name'] ?? []]"
            :items="[account.last_name, account.first_name]"
            :itemKeys="['last_name', 'first_name']"
            required="true"
            @updateItem="updateAccountProp"
          />
          <TextFields
            :title="i18n.global.t('pages.accounts.form.nameKana')"
            :labels="[
              i18n.global.t('pages.accounts.form.lastNameKana'),
              i18n.global.t('pages.accounts.form.firstNameKana')
            ]"
            :errors="[
              errors['last_name_kana'] ?? [],
              errors['first_name_kana'] ?? [],
            ]"
            :items="[account.last_name_kana, account.first_name_kana]"
            :itemKeys="['last_name_kana', 'first_name_kana']"
            required="true"
            @updateItem="updateAccountProp"
          />
          <Select
            :title="i18n.global.t('pages.accounts.accountCategory')"
            required="true"
            v-model="selectedOfficeableType"
            itemTitle="name"
            itemValue="id"
            :disabled="account.enabled_driver_mode"
            :items="showableOfficeableTypes"
            :errors="errors['officeable_type'] ?? []"
          />
          <FormRow
            :title="i18n.global.t('pages.accounts.officeableName')"
            :required="true"
          >
            <OrganizationAutoComplete
              label=""
              v-model="selectedOffice"
              :selectedOfficeableType="selectedOfficeableType"
              :clearable="false"
              :errorMessages="errors['officeable_id'] ?? []"
            />
          </FormRow>
          <Select
            v-show="showableRoleForm"
            :title="i18n.global.t('pages.accounts.role')"
            required="true"
            v-model="selectedRole"
            itemTitle="name"
            :itemValue="value + ' ' + name"
            :disabled="account.enabled_driver_mode"
            :items="roles"
            :errors="errors['role_id'] ?? []"
          />
          <AccountTextRow
            v-show="showableDriverIdForm"
            :title="i18n.global.t('pages.accounts.driverId')"
            :errors="errors['driver_id'] ?? []"
            v-model="account.driver_id"
            required="true"
          />
          <AccountTextRow
            :title="i18n.global.t('pages.accounts.telephoneNumber')"
            :errors="errors['telephone_number'] ?? []"
            v-model="account.telephone_number"
          />
          <AccountTextRow
            :title="i18n.global.t('pages.accounts.form.idExternalAsp')"
            :errors="errors['id_external_asp'] ?? []"
            v-model="account.id_external_asp"
          />
          <PasswordForm
            :title="i18n.global.t('pages.accounts.form.passExternalAsp')"
            :errors="errors['pass_external_asp'] ?? []"
            v-model="account.pass_external_asp"
            :maskable="true"
          />
          <DepartmentColumn
            v-model="selectedDepartment"
            :selectedOfficeableType="selectedOfficeableType"
            :selectedOffice="selectedOffice"
            :initialItemId="initialDepartmentId"
            :errors="errors['department_id'] ?? []"
            :isDepartment="isDepartment"
          />
          <ToggleRow
            :title="$t('pages.accounts.form.sendCvrUploadMail')"
            v-model="account.send_cvr_upload_mail"
          />
          <TextArea
            :title="i18n.global.t('pages.accounts.form.comment')"
            :errors="errors['comment'] ?? []"
            :item="account.comment"
            itemKey="comment"
            @updateItem="updateAccountProp"
          />
        </div>
        <div class="d-flex justify-content-end">
          <v-btn
            v-show="createAndUpdateAuthority && !loading"
            class="exec-btn"
            :loading="creatingOrUpdating"
            @click="createOrUpdate"
          >
            {{ i18n.global.t('pages.common.button.conservation') }}
          </v-btn>
        </div>
      </div>
      <InfoMessageModal
        :state="modalState"
        :email="account.email"
        :password="account.password"
        :name="[account.last_name, account.first_name]"
        :loading="creatingOrUpdating"
        @close="modalState = false"
        @exec="createOrUpdate(false)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import FormRow from "@/components/Common/FormRow"
import AccountTextRow from "@/components/AccountParts/AccountTextRow";
import PasswordForm from "@/components/AccountParts/PasswordForm"
import "gitart-vue-dialog/dist/style.css";
import Select from "@/components/AccountParts/SelectRow";
import TextFields from "@/components/AccountParts/TextFieldsRow";
import TextArea from "@/components/AccountParts/TextAreaRow";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";
import ToggleRow from "@/components/AccountParts/ToggleRow"
import InfoMessageModal from "@/components/AccountParts/InfoMessageModal.vue"
import { useAuth } from "@/services/user/Auth0UserProvider";
import { generatePassword, generateEmail } from "@/services/functions";
import { scrollToErrorComponent } from '@/services/validators.ts';
import DepartmentColumn from "@/components/AccountParts/DepartmentColumn.vue"
import { DEPARTMENT_OFFICE_TYPE, MANAGER_ROLE_ID, DRIVER_ROLE_ID } from "@/services/consts.ts"
import OrganizationAutoComplete from "@/components/AccountParts/OrganizationAutoComplete"; 
import { DEFAULT_SELECTED_OFFICE } from "@/services/consts/account";
import i18n from '@/lang/i18n';

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    showFlash: {
      type: Function,
    },
    switchContentLoading: {
      type: Function,
    },
  },
  data: () => ({
    title: "",
    accountId: null,
    account: {},
    managementOffice: {
      id: 1,
      name: i18n.global.t('pages.accounts.managementOffice'),
      type: "ManagementOffice"
    },
    subManagementOffice: {
      id: 2,
      name: i18n.global.t('pages.accounts.subManagementOffice'),
      type: "SubManagementOffice",
    },
    installationOffice: {
      id: 3,
      name: i18n.global.t('pages.accounts.installationOffice'),
      type: "InstallationOffice"
    },
    operationOffice: {
      id: 4,
      name: i18n.global.t('pages.accounts.operationOffice'),
      type: "OperationOffice"
    },
    department: {
      id: 5,
      name: i18n.global.t('pages.accounts.department'),
      type: "Department"
    },
    offices: [],
    selectedOfficeableType: { id: null, name: null, type: null },
    blankOfficeableType: { id: null, name: null, type: null },
    showableOfficeableTypes: [],
    selectedOffice: DEFAULT_SELECTED_OFFICE,
    selectedDepartment: { id: null, operation_office_id: null, name: null },
    showableRoleForm: false,
    selectedRole: { name: null, value: null },
    blankRole: { name: null, value: null },
    roles: [
      { name: i18n.global.t('pages.accounts.administrator'), value: MANAGER_ROLE_ID},
      { name: i18n.global.t('pages.accounts.driver'), value: DRIVER_ROLE_ID },
    ],
    blankAuthority: { id: null, name: null },
    showableDriverIdForm: false,
    disableWatchOfficeableType: false,
    disableWatchOffice: false,
    loading: false,
    invalidAccountProps: [
      "status",
      "created_at",
      "created_by",
      "deleted_at",
      "updated_at",
      "updated_by",
      "group_id",
      "login_times",
      "sub",
      "synced_at",
      "email_verified_at",
      "department",
      "department_target_name",
      "pwd_last_updated_at",
      "lang",
    ],
    errors: {},
    creatingOrUpdating: false,
    createAndUpdateAuthority: false,
    loginWithRedirect: null,
    modalState: false,
    beforeEmail: "",
    account_officeable_type: null,
    initialDepartmentId: null,
    isDepartment: false,
  }),
  mounted() {
    this.switchContentLoading(true);
    this.accountId = this.$route.params.id;
    this.title = this.accountId ?
      i18n.global.t('pages.accounts.title.edit') :
      i18n.global.t('pages.accounts.title.create');
    document.title = this.title;

    (async () => {
      const authorityList = await AuthorityCacheApi.getWithParse();
      if (this.accountId) {
        this.createAndUpdateAuthority = authorityList["octlink.user.update"];
      } else {
        this.createAndUpdateAuthority = authorityList["octlink.user.create"];
      }

      if (!this.accountId && !this.createAndUpdateAuthority) {
        this.showFlash(i18n.global.t('pages.common.messages.forbidden'), "error", false);
        this.$router.push("/accounts");
        return;
      }

      const { loginWithRedirect } = useAuth();
      this.loginWithRedirect = loginWithRedirect;

      this.account.email = null;
      this.account.login_id = this.get6DigitRandomNumbers();
      this.account.no_email = false;

      this.getSelfAccount();
    })();
  },
  methods: {
    createOrUpdate(bool = true) {
      if (this.checkEmailAndPassword(bool)) return this.displayModal()
      this.creatingOrUpdating = true;
      const params = JSON.parse(JSON.stringify(this.account)); // deep copy
      const isOperationOffice = this.selectedOfficeableType.type == this.operationOffice.type;

      params["no_email"] = params["no_email"] ? 1 : 0;
      params["officeable_type"] = this.selectedOfficeableType.id;
      params["officeable_id"] = this.selectedOffice.id;
      params["role_id"] = isOperationOffice ? this.selectedRole.value : MANAGER_ROLE_ID;
      if (params["role_id"] != DRIVER_ROLE_ID) params["driver_id"] = null;
      params["department_id"] = this.selectedDepartment?.id ?? null;

      if (!this.isOperationOffice) params["enabled_driver_mode"] = false;

      this.invalidAccountProps.forEach((prop) => delete params[prop]);
      if (!params["enabled_driver_mode"]) delete params["password"];

      const apiRequetFunc = this.accountId
        ? Api().updateAccount
        : Api().createAccount;

      apiRequetFunc(params)
        .then((data) => {
          this.creatingOrUpdating = false;
          this.showFlash(i18n.global.t('pages.common.messages.success'), "success");
          this.$router.push("/accounts");
        })
        .catch((error) => {
          this.modalState = false
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.errors = res.data.errors;
              scrollToErrorComponent();
          }
          this.creatingOrUpdating = false;
        });
    },
    get6DigitRandomNumbers() {
      const min = 10 ** 5;
      const max = 10 ** 6 - 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    getSelfAccount() {
      this.loading = true;
      Api()
        .getSelfAccount()
        .then((data) => {
          this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
          const roleId = data.role_id;
          if (roleId == 2) {
            this.loading = false;
            this.switchContentLoading(false);
            this.showFlash(i18n.global.t('pages.common.messages.forbidden'), "error", false);
            this.$router.push("/accounts");
            return;
          }
          const type = data.login_organization_type.split("\\")[2];
          this.account_officeable_type = type
          switch (type) {
            case this.managementOffice.type:
              this.showableOfficeableTypes = [
                this.managementOffice,
                this.subManagementOffice,
                this.installationOffice,
                this.operationOffice,
              ];
              break;
            case this.subManagementOffice.type:
              this.showableOfficeableTypes = [
                this.subManagementOffice,
                this.installationOffice,
                this.operationOffice,
              ];
              break;
            case this.department.type:
              this.showableOfficeableTypes = [this.operationOffice];
              break;
            case this.operationOffice.type:
              this.showableOfficeableTypes = [this.operationOffice];
              break;
            default:
              this.showableOfficeableTypes = [];
          }
          if (this.showableOfficeableTypes.length > 0) {
            this.showableOfficeableTypes.unshift(this.blankOfficeableType);
          }

          this.getOffices();
        })
        .catch((error) => {
          const res = error.response;
          if (res.status == 401) {
            this.loginWithRedirect({
              appState: { targetUrl: location.pathname },
            });
          }
        });
    },
    getOffices() {
      Api()
        .getOffices()
        .then((data) => {
          const offices = [];
          data.forEach((office) =>
            offices.push({
              id: office.id,
              type: office.type.split("\\")[2],
              name: office.company_name ?? office.operation_office_name,
              departments: office.departments ?? [],
              authorities: office.authorities ?? [],
            })
          );
          this.offices = offices;

          if (!this.accountId) {
            this.loading = false;
            this.switchContentLoading(false);
            return;
          }

          this.getAccount();
        });
    },
    getAccount() {
      this.disableWatchOfficeableType = true;
      this.disableWatchOffice = true;

      Api()
        .getAccount(this.accountId)
        .then((data) => {
          this.account = JSON.parse(JSON.stringify(data)); // deep copy
          this.beforeEmail = this.account.email
          this.account.no_email = data.temporary_email?.length > 0;
          this.initialDepartmentId = data.department_id

          const officeableType = data.officeable_type.split("\\")[2];
          let office = null;
          switch (officeableType) {
            case this.managementOffice.type:
              office = this.managementOffice;
              break;
            case this.subManagementOffice.type:
              office = this.subManagementOffice;
              break;
            case this.operationOffice.type:
              office = this.operationOffice;
              break;
            default:
              office = this.installationOffice;
          }
          this.selectedOfficeableType = office;

          this.resetOffices(this.selectedOfficeableType, data.officeable_id);

          if (officeableType == this.operationOffice.type) {
            this.showableRoleForm = true;
            this.selectedRole = this.roles.find(
              (role) => role.value == data.role_id
            );
          }

          this.loading = false;
          this.switchContentLoading(false);
        })
        .catch((error) => {
          const res = error.response;
          if (res.status == 403) {
            this.showFlash(i18n.global.t('pages.common.messages.notEditAuthority'), "error", true);
            this.$router.push("/accounts/");
          }
        });
    },
    resetOffices(officeableType, officeableId = null) {
      let filteredOffices = []
      if (officeableType.type) {
        filteredOffices = this.offices.filter(
          (office) => office.type == officeableType.type
        );
      }

      this.selectedOffice = officeableId
        ? filteredOffices.find((office) => office.id == officeableId)
        : DEFAULT_SELECTED_OFFICE;
    },
    updateAccountProp(key, value) {
      this.account[key] = value;
    },
    updateSelectedItem(key, value) {
      this[key] = value;
    },
    makeMailAdress() {
      if (!this.account.email) this.account.email = generateEmail(this.account.driver_id, this.selectedOffice.id)
    },
    checkEmailAndPassword(bool) {
      if (!bool) return bool
      const diffEmail = this.beforeEmail != this.account.email
      const existsName = this.account.last_name && this.account.first_name
      const existsOfficeAndDriverId = this.account.driver_id && this.selectedOffice.id
      const isNecessaryPassword = this.passwordConditions()

      return (diffEmail || !this.account.email || isNecessaryPassword) && existsName && existsOfficeAndDriverId && this.account.enabled_driver_mode
    },
    displayModal() {
      this.makeMailAdress()
      this.makePassword()
      this.modalState = true
    },
    makePassword() {
      if (!this.accountId && !this.account.password) {
        this.account.password = this.account.driver_id ? generatePassword(this.account.driver_id) : ""
      }
    },
    passwordConditions() {
      if (this.accountId) return !!this.account.password
      return !this.account.password && this.account.driver_id
    }
  },
  computed: {
    isOperationOffice() {
      return this.selectedOfficeableType?.type == "OperationOffice";
    },
    isManagementOffice() {
      return this.account_officeable_type == "ManagementOffice";
    },
  },
  watch: {
    "account.enabled_driver_mode": {
      handler(enabledDriverMode) {
        if (enabledDriverMode) {
          this.selectedRole = this.roles[1];
          this.account.no_email = false;
          if (this.account.driver_id && this.selectedOffice.id) this.makeMailAdress()
        }
      },
    },
    selectedOfficeableType: {
      handler(officeableType) {
        if (this.disableWatchOfficeableType) {
          this.disableWatchOfficeableType = false;
          return;
        }
        this.resetOffices(officeableType);
        this.showableRoleForm =
          officeableType.type == this.operationOffice.type;
      },
    },
    selectedOffice: {
      handler(office) {
        if (this.disableWatchOffice) {
          this.disableWatchOffice = false;
          return;
        }
      },
    },
    showableRoleForm: {
      handler(showableRoleForm) {
        const driverRole = this.roles[1];
        this.showableDriverIdForm =
          showableRoleForm && this.selectedRole.value == driverRole.value;
      },
    },
    selectedRole: {
      handler(role) {
        const driverRole = this.roles[1];
        this.showableDriverIdForm = role.value == driverRole.value;
      },
    },
  },
  beforeUnmount() {
    this.switchContentLoading(false);
  },
  components: {
    Select,
    TextFields,
    TextArea,
    ToggleRow,
    FormRow,
    AccountTextRow,
    PasswordForm,
    InfoMessageModal,
    DepartmentColumn,
    OrganizationAutoComplete,
  },
};
</script>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *,
.v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
