<script setup>
import { reactive, watch } from 'vue';

const props = defineProps({
  externalUrls: { type: Object },
  errors: { type: Object },
  windowInnerWidth: Number,
});
const emit = defineEmits(['inputValue']);
const data = reactive({ external_urls: {} });

const unwatch = watch(
  () => props.externalUrls,
  (propsExternalUrls) => {
    data.external_urls = propsExternalUrls;
    unwatch();
  }
);
function title(i) {
  if (i == 0) return '外部ASP_URL';
  return '';
}
function startEdit() {
  data.external_urls.push({ name: '', url: '' });
}
watch(data, () => {
  emit('inputValue', data);
});
</script>
<template>
  <template v-if="false">
    <v-row
      class="text-form pl-4 pr-3 mb-3"
      v-for="(externalUrl, i) in data.external_urls"
      :key="i"
    >
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pl-4 align-center">{{ title(i) }}</h6>
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 4 : 4.5" class="pt-0">
        <v-text-field
          label="名称 &nbsp; (例)動態管理"
          v-model="data.external_urls[i].name"
          :error-messages="errors[`external_urls.${i}.name`]"
        />
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 4 : 4.5" class="pt-0">
        <v-text-field
          label="URL &nbsp; (例)https://"
          v-model="data.external_urls[i].url"
          :error-messages="errors[`external_urls.${i}.url`]"
        />
      </v-col>
      <v-col cols="1" class="pt-0 pl-0" v-if="i == 0">
        <v-btn
          @click="startEdit(externalUrl)"
          class="ml-3"
          style="background-color: #dddddd; max-width: 30px"
        >
          +
        </v-btn>
      </v-col>
      <v-col cols="1" class="pt-0" v-else />
    </v-row>
  </template>
</template>
