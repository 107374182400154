import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {RouteLocationNormalized, NavigationGuardNext} from 'vue-router'
import DashboardLayout from '../layout/DashboardLayout.vue'
import IrregularLayout from '../layout/IrregularLayout.vue'
import SelectRole from '../views/SelectRole.vue'
import DashBoard from '../views/DashBoard.vue'
import AccountList from '../views/AccountList.vue'
import AccountForm from '../views/AccountForm.vue'
import DailyReport from '../views/DailyReport.vue'
import DailyReportLayout from '../views/DailyReportLayout.vue'
import MonthlyReport from '../views/MonthlyReport.vue'
import FirmwareList from '../views/Firmware/FirmwareListViewPage.vue'
import DeviceFirmware from '../views/Firmware/DeviceFirmwareView.vue'
import SafeDrivingReport from '../views/SafeDrivingReport.vue'
import Every24hoursReport from '../views/Every24hoursReport.vue'
import ManageSpot from '../views/ManageSpot.vue'
import ManageVehicle from '../views/ManageVehicle.vue'
import DeviceList from '../views/DeviceList.vue'
import DeviceForm from '../views/DeviceForm.vue'
import ManageVehicleForm from '../views/ManageVehicleForm.vue'
import SafeDrivingSetting from '../views/SafeDrivingSetting.vue'
import OperationOfficeForm from '../views/OperationOfficeEditForm.vue'
import OperationOfficeList from '../views/OperationOfficeList.vue'
import OperationOfficeFormForManagementOfficeOrSubManagementOffice from '../views/OperationOfficeForm.vue'
import InstallationOfficeList from '../views/InstallationOfficeList.vue'
import InstallationOfficeForm from '../views/InstallationOfficeForm.vue'
import DownloadData from '../views/DownloadData.vue'
import DownloadDataHistories from '../views/DownloadDataHistories.vue'
import GroupList from '../views/GroupList.vue'
import GroupForm from '../views/GroupForm.vue'
import CvrConvert from '../views/CvrConvert.vue'
import TemperatureSensorCharts from '../views/TemperatureSensorCharts.vue'
import TemperaturInformationNumerical from "../views/TemperaturInformationNumerical.vue"
import MonthlyReportWorkingTimesSetting from '../views/MonthlyReportWorkingTimesSetting.vue'
import AllowAccessOfficeList from '../views/AllowAccessOfficeList.vue'
import DataLinkage from '../views/DataLinkage.vue'
import NotFound from '../views/NotFound.vue'
import ForbiddenPage from '../views/ForbiddenPage.vue'
import {watchEffect} from 'vue';
import {useAuth} from "@/services/user/Auth0UserProvider"
import {routingAuthorityNames} from "@/services/consts"
import AccessChecker, {getAccessCheckerInstance} from "@/router/accessChecker";
import SelectRoleChecker from '@/router/selectRoleChecker'
import {getSiteLink} from "@/router/links";
import SubManagementOfficeList from "@/views/SubManagementOfficeList.vue";
import SubManagementOfficeForm from "@/views/SubManagementOfficeForm.vue";
import UnexpectedEventAndAlertStatuseList from "@/views/UnexpectedEventAndAlertStatuseList.vue"
import RoleAssaignment from '@/views/RoleAssaignment.vue';
import DepartmentList from "@/views/DepartmentList.vue"
import UserRoleList from "@/views/UserRoleList.vue"
import UserRoleForm from "@/views/UserRoleForm.vue"
import Every24HoursReportPreview from "@/views/Every24HoursReportPreview.vue";
import TenkoAssociation from "@/views/TenkoAssociation.vue"; 

const useRouteGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
  checkAccessHandle: (accessChecker: AccessChecker) => boolean
) => {
  const {isAuthenticated, loading, loginWithRedirect} = useAuth();

  const checkRole = async() => {
    if (!verify()) return false
    const currentRole = await SelectRoleChecker.getCurrentRole()
    if (!currentRole) router.push('select_role')
  }

  const goNextOrRoot = async () => {
    if (!verify()) return false
    return checkAccessHandle(await getAccessCheckerInstance()) ? next() : next({path: '/'})
  }

  const verify = () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      return true
    }

    // Otherwise, log in
    loginWithRedirect({appState: {targetUrl: to.fullPath}})
    return false;
  }

  // If loading has already finished, check our auth state using `verify()`
  if (!loading.value) return goNextOrRoot()

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      checkRole()
      return goNextOrRoot()
    }
  })
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      createRouterLinkBySiteLink(
        'dashboard',
        DashBoard
      ),
      createRouterLinkBySiteLink(
        'firmwares',
        FirmwareList
      ),
      createRouterLinkBySiteLink(
        'FirmwareDevices',
        DeviceFirmware
      ),
      createRouterLinkBySiteLink(
        'Accounts',
        AccountList
      ),
      {
        path: 'accounts/form/:id?',
        name: 'AccountForm',
        component: AccountForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.AccountForm)
          })
        },
      },
      createRouterLinkBySiteLink(
        'DailyReport',
        DailyReport
      ),
      createRouterLinkBySiteLink(
        'DailyReportLayout',
        DailyReportLayout
      ),
      createRouterLinkBySiteLink(
        'TemperatureSensorCharts',
        TemperatureSensorCharts
      ),
      createRouterLinkBySiteLink(
        'TemperaturInformationNumerical',
        TemperaturInformationNumerical
      ),
      createRouterLinkBySiteLink(
        'Every24hoursReport',
        Every24hoursReport
      ),
      {
        path: 'every24hours-report/preview',
        name: 'Every24HoursReportPreview',
        component: Every24HoursReportPreview,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.Every24hoursReport)
          })
        },
      },
      createRouterLinkBySiteLink(
        'MonthlyReport',
        MonthlyReport
      ),
      createRouterLinkBySiteLink(
        'SafeDrivingReport',
        SafeDrivingReport
      ),
      createRouterLinkBySiteLink(
        'Spot',
        ManageSpot
      ),
      createRouterLinkBySiteLink(
        'Vehicle',
        ManageVehicle
      ),
      {
        path: 'vehicles/form/:id?',
        name: 'VehicleForm',
        component: ManageVehicleForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.VehicleForm)
          })
        },
      },
      createRouterLinkBySiteLink(
        'SafeDrivingSetting',
        SafeDrivingSetting
      ),
      createRouterLinkBySiteLink(
        'OperationOfficeEditForm',
        OperationOfficeForm
      ),
      createRouterLinkBySiteLink(
        'OperationOfficeList',
        OperationOfficeList
      ),
      {
        path: 'operation-offices/form/:id?',
        name: 'OperationOfficeForm',
        component: OperationOfficeFormForManagementOfficeOrSubManagementOffice,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return (
              accessChecker.hasUserAccessTo(routingAuthorityNames.OperationOffice)
            )
          })
        },
      },
      createRouterLinkBySiteLink(
        'DownloadData',
        DownloadData
      ),
      createRouterLinkBySiteLink(
        'DownloadDataHistories',
        DownloadDataHistories
      ),
      createRouterLinkBySiteLink(
        'Groups',
        GroupList
      ),
      {
        path: 'groups/form/:id?',
        name: 'GroupForm',
        component: GroupForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.GroupForm)
          })
        },
      },
      createRouterLinkBySiteLink(
        'CvrConvert',
        CvrConvert
      ),
      createRouterLinkBySiteLink(
        'InstallationOffice',
        InstallationOfficeList
      ),
      {
        path: 'installation-offices/form/:id?',
        name: 'InstallationOfficeForm',
        component: InstallationOfficeForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.InstallationOffice)
          })
        },
      },
      createRouterLinkBySiteLink(
        'AllowAccessOffice',
        AllowAccessOfficeList
      ),
      createRouterLinkBySiteLink(
        'DeviceList',
        DeviceList
      ),
      {
        path: 'devices/form/:id?',
        name: 'DeviceForm',
        component: DeviceForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.Device)
          })
        },
      },
      {
        path: 'monthly-report/working-times/edit',
        name: 'MonthlyReportWorkingTimesSetting',
        component: MonthlyReportWorkingTimesSetting,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.MonthlyReport);
          })
        },
      },
      createRouterLinkBySiteLink(
        'DataLinkage',
        DataLinkage
      ),
      createRouterLinkBySiteLink(
        'SubManagementOffices',
        SubManagementOfficeList
      ),
      {
        path: 'sub-management-offices/form/:id?',
        name: 'subManagementOfficeForm',
        component: SubManagementOfficeForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.SubManagementOffice);
          })
        },
      },
      createRouterLinkBySiteLink(
        'UnexpectedEventAndAlertStatuseList',
        UnexpectedEventAndAlertStatuseList
      ),
      createRouterLinkBySiteLink(
        'RoleAssaignment',
        RoleAssaignment
      ),
      createRouterLinkBySiteLink(
        'Departments',
        DepartmentList
      ),
      createRouterLinkBySiteLink(
        'UserRoles',
        UserRoleList
      ),
      {
        path: 'user-roles/form/:id?',
        name: 'userRoleForm',
        component: UserRoleForm,
        beforeEnter: (to, from, next) => {
          useRouteGuard(to, from, next, (accessChecker) => {
            return accessChecker.hasUserAccessTo(routingAuthorityNames.UserRoles);
          })
        },
      },
      createRouterLinkBySiteLink(
        'TenkoAssociation',
        TenkoAssociation
      ),
    ]
  },
  {
    path: '/',
    component: IrregularLayout,
    children: [
      createRouterLinkBySiteLink(
        'select_role',
        SelectRole
      ),
      {
        path: '/forbidden',
        component: ForbiddenPage,
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }
]

function createRouterLinkBySiteLink (
  name: string,
  component: any,
): RouteRecordRaw {

  const siteLink = getSiteLink(name)
  if ( !siteLink ){
    throw new Error('Not found link');
  }

  return {
    path: siteLink.path,
    name: siteLink.name,
    component,
    beforeEnter: (to, from, next) => {
      useRouteGuard(to, from, next, siteLink.accessCheckHandler)
    },
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
