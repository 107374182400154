<template>
  <div class="d-flex" style="min-height: 45vw">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <div class="col-5">
          <h4 class="card-title mt-0">{{ title }}</h4>
        </div>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="w-100 mb-2 mt-1 position-relative">
        <div
          class="d-flex"
          :class="buttonsStyle"
        >
          <v-btn
            v-if="isDisplayCvrList"
            class="m-1"
            @click="changeDisplayCvrList(false)"
          >
            {{ i18n.global.t('pages.common.button.return') }}
          </v-btn>
          <div>
            <v-btn
              @click="reload"
              class="m-1"
            >
              {{ i18n.global.t('pages.common.button.displayReload') }}
            </v-btn>
            <UploadPanel
              v-if="createAuthority"
              :reload="reload"
              :showFlash="showFlash"
            />
          </div>
        </div>
      </div>
      <div :class="listsClass">
        <VehiclesList
          ref="VehiclesListComponent"
          :reload="reload"
          :showFlash="showFlash"
          :openCvrList="changeDisplayCvrList"
          :isDisplayCvrList="isDisplayCvrList"
          :selectedVehicle="selectedVehicle"
          :createAuthority="createAuthority"
        />
        <div
          v-if="isDisplayCvrList"
          :class="cvrListClass"
        >
          <List
            v-model="cvrUploadsFilterParams"
            :items="items"
            :accounts="accounts"
            :showFlash="showFlash"
            :reload="reload"
            :listLoading="listLoading"
            :updateAuthority="updateAuthority"
            @search="getCvrUploads"
          />
          <PagenateItems :range="2" :totalPage="totalPage" v-model="currentPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DEFAULT_SELECTED_STATUSES } from "@/services/consts/cvr_upload.ts";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import UploadPanel from "@/components/CvrConvert/UploadPanel";
import List from "@/components/CvrConvert/CvrUploadList";
import PagenateItems from "@/components/PagenateItems";
import Api from "@/services/api/ApiServiceFabrick";
import { ref, computed, onMounted, watch } from "vue";
import ManualLink from "@/components/ManualLink";
import VehiclesList from "@/components/CvrConvert/VehiclesListParts/VehiclesList.vue"
import i18n from '@/lang/i18n'

const props = defineProps(["showFlash"]);
const showFlash = ref(props.showFlash);
const pageName = "cvrUploadList";
const title = i18n.global.t('pages.cvr_uploads.title')
document.title = title;

const currentPage = ref(1);
const totalPage = ref(0);
const items = ref([]);
const PARENT_ONLY = 1;
const listLoading = ref(false);
const accounts = ref([]);
const isDisplayCvrList = ref(false);
const selectedVehicle = ref({});
const VehiclesListComponent = ref(null);
const createAuthority = ref(true);
const updateAuthority = ref(true);
const cvrUploadsFilterParams = ref({
  status: DEFAULT_SELECTED_STATUSES
})

onMounted(() => {
  getAuthority()
  getAccounts()
})

const getAuthority = async() => {
  const authorityList = await AuthorityCacheApi.getWithParse()
  createAuthority.value = !!authorityList["octlink.cvr_upload.create"];
  updateAuthority.value = !!authorityList["octlink.cvr_upload.update"];
}

const getAccounts = () => {
  Api().autoCompleteAccounts().then((data) => {
    accounts.value = data
  })
}

const reload = () => {
  currentPage.value = 1;
  VehiclesListComponent.value.getCvrUploadSummaries()
  if (isDisplayCvrList.value) getCvrUploads()
};

const getCvrUploads = () => {
  listLoading.value = true
  const params = makeQueryParams()
  Api().getCvrUploads(params).then((data) => {
    listLoading.value = false
    items.value = data.items
    currentPage.value = data.current_page;
    totalPage.value = data.total_page;
  }).catch((err) => {
    listLoading.value = false
    switch (err.response.status) {
      case 403:
        location.reload();
        break;
    }
  });
}

function makeQueryParams() {
  return {
    page: currentPage.value,
    parentOnly: PARENT_ONLY,
    deviceId: selectedVehicle.value.device_id,
    statuses: cvrUploadsFilterParams.value.status.join(',')
  }
}

const buttonsStyle = computed(() => {
  return isDisplayCvrList.value ? 'justify-space-between' : 'justify-end'
})

const listsClass = computed(() => {
  return isDisplayCvrList.value ? 'd-flex w-100' : ''
})

const cvrListClass = computed(() => {
  return isDisplayCvrList.value ? 'cvr-list display' : 'cvr-list'
})

const changeDisplayCvrList = (bool, vehicle = null) => {
  isDisplayCvrList.value = bool
  if (listLoading.value) return
  if (isDisplayCvrList.value) {
    items.value = []
    currentPage.value = 1;
    selectedVehicle.value = vehicle
    cvrUploadsFilterParams.value.status = DEFAULT_SELECTED_STATUSES
    getCvrUploads()
  }
}

watch(currentPage, () => {
  getCvrUploads()
});
</script>

<style scoped>
.cvr-list {
  z-index: 999;
  right: -120%;
	width: 65%;
	transition: 0.5s;
}
.cvr-list.display {
  right: 0;
  width: 90%;
  margin-left: 25px;
}
</style>
