<template>
  <div class="daily-report-layout-list">
    <template v-if="listLoading">
      <div class="not-searched">
        <label>
          {{ i18n.global.t('pages.common.list.reading') }}
        </label>
      </div>
    </template>
    <div class="table-scrollbar">
      <v-table>
        <thead>
          <tr>
            <th class="col-md-1" >
              {{ i18n.global.t('pages.dailyReportLayout.list.id') }}
            </th>
            <th class="col-md-4" >
              {{ i18n.global.t('pages.dailyReportLayout.name') }}
            </th>
            <th class="col-md-1" >
              {{ i18n.global.t('pages.dailyReportLayout.list.note') }}
            </th>
            <th class="col-md-2" >
              {{ i18n.global.t('pages.dailyReportLayout.list.updateAt') }}
            </th>
            <th class="col-md-3" >
              {{ i18n.global.t('pages.dailyReportLayout.list.updateBy') }}
            </th>
            <th class="col-md-1" >
              {{ i18n.global.t('pages.dailyReportLayout.list.edit') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
          <tr
            v-for="item in items"
            :key="'item_id_' + item?.id"
          >
            <td> {{item.id}}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.is_note }}</td>
            <td>{{ item.updated_at?.split(" ")[0] }}</td>
            <td>{{ item.updated_by.last_name + item.updated_by.first_name }}</td>
            <td>
              <UpdateModal v-if="props.authorityList['octlink.daily_report_layout.update']"
                :item="item"
                @updateItem="updateItem"
                :showFlash="showFlash"
                icon="edit_note"
                :label="i18n.global.t('pages.dailyReportLayout.list.update')"
              />
              <DeleteModal v-if="props.authorityList['octlink.daily_report_layout.delete']"
                :item="item"
                @change="updateItem"
                :showFlash="showFlash"
              />
            </td>
          </tr>
          </template>
          <template v-else-if="!listLoading">
            <tr>
              <td colspan="9">
                {{ i18n.global.t('pages.common.list.noData') }}
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script setup>
  import UpdateModal from "@/components/DailyReportLayoutParts/UpdateModal"
  import DeleteModal from "@/components/DailyReportLayoutParts/DeleteModal"
  import { ref, defineProps, defineEmits, toRefs, watch } from 'vue'
  import i18n from '@/lang/i18n';

  const props = defineProps({
    items: [],
    listLoading: {
      type: Boolean,
      default: false
    },
    showFlash: {
      type: Function
    },
    authorityList: [],
  });

  const {items, listLoading, showFlash} = toRefs(props);
  const form = ref({});

  const emit = defineEmits(['changeItem'])
  const updateItem = (item) => {
    emit('changeItem');
  };
</script>

<style>
.daily-report-layout-list .v-table__wrapper{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
</style>
<style scoped>
.daily-report-layout-list {
  position: relative;
}
.daily-report-layout-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
  min-width: 7em;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
</style>
