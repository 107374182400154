export default {
  title: "IT点呼データ連携",
  list: {
    header: {
      id: "ID",
      drivarName: "ドライバー名",
      email: "Eメール",
      operationOfficeName: "運営会社",
      department: "組織",
      driverId: "ドライバーID",
      employeeCode: "社員コード",
      updatedAt: "最終更新日",
      updatedBy: "最終更新者",
      edit: "編集",
      delete: "削除",
    },
  },
  filter: {
    driverName: "ドライバー名",
  },
  editModal: {
    title: "IT点呼データ連携編集",
    operationOffice: "運営会社",
    driverId: "ドライバーID",
    employeeCode: "社員コード",
  },
  deleteModal: {
    title: "IT点呼データ連携削除",
  },
  errorMessage: {
    operationOffice: {
      required: "運営会社を選択してください",
    },
  },
}
