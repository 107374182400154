<script setup>
  import { reactive, ref, watch } from 'vue'

  const props = defineProps({
    operationOfficeInfo: Object,
    operationOfficeLists: Array,
    windowInnerWidth: Number
  })
  const emits = defineEmits(['selected'])
  const title = "運営会社"
  const officeInCharges = reactive({ value: [] })
  const manageOfficeOfCompanies = reactive({ value: [] })
  const selectedList = reactive({ value: [] })
  const candidateList = reactive({ value: [] })

  watch(() => props.operationOfficeLists, (officeList) => {
    manageOfficeOfCompanies.value = officeList
  })

  watch(() => props.operationOfficeInfo, (info) => {
    const targetIds = pluckIdListString(info)
    const selectedIds = pluckIdListString(selectedList.value)
    if (selectedIds == targetIds) return

    officeInCharges.value = info
    selectedList.value = officeInCharges.value.map((office) => {
      return { id: office.id, operation_office_name: office.operation_office_name }
    })
  })

  watch(() => manageOfficeOfCompanies.value, () => {
    makeCandidateAndSelectedLists(selectedList.value)
  })

  watch(() => selectedList.value, (selected) => {
    makeCandidateAndSelectedLists(selected)

    const emitValus = selected.filter((office) => { return !manageOfficeOfCompanies.value.includes(office.id) })
    emits('selected', { office_in_charge: emitValus })
  })

  function makeCandidateAndSelectedLists (selected) {
    const officeIds = selected.map((office) => { return office.id })
    if (manageOfficeOfCompanies.value == {}) return
    const candidateAccounts = manageOfficeOfCompanies.value.filter((office) => { return !officeIds.includes(office.id) })
    candidateList.value = candidateAccounts.map((office) => {
      return { id: office.id, operation_office_name: office.name }
    })
  }

  const selectInSelectedList = ref([])
  const selectInCandidateList = ref([])

  const addOfficeInCharge = () => {
    const targetAccounts = candidateList.value.filter((officeInfo) => {
      return Object.values(selectInCandidateList.value).includes(officeInfo.id)
    })

    selectedList.value = selectedList.value.concat(targetAccounts)
  }

  const clearOfficeInCharge = () => {
    selectedList.value = selectedList.value.filter((officeInfo) => {
      return !Object.values(selectInSelectedList.value).includes(officeInfo.id)
    })
  }

  const pluckIdListString = (arr) => {
    const ids = []
    arr.forEach((obj) => {
      ids.push(obj.id)
    })
    return JSON.stringify(ids.sort())
  }
</script>

<template>
  <div v-show="windowInnerWidth > 800" class="mb-4">
    <v-row class="checkbox-with-number-text-form pl-4 pr-3 mb-2">
      <v-col cols="2">
        <h6 class="pt-2 pl-4 fw-bold">{{title}}</h6>
      </v-col>
      <v-col cols="10" class="pt-0">
        <v-row>
          <v-col cols="5">
            <div>
              <div class="pt-2 fw-bold label">一覧</div>
              <select class="form-control form-height" multiple="multiple" v-model="selectInCandidateList">
                <option v-for="(officeInfo, index) in candidateList.value" :key="index" :value="officeInfo.id">
                  {{officeInfo.operation_office_name}}
                </option>
              </select>
            </div>
          </v-col>
          <v-col>
            <div class="text-center mt-40px">
              <div>
                <v-btn
                  class="mb-2 fw-bold btn-color"
                  @click="addOfficeInCharge"
                >
                  ▶︎
                </v-btn>
              </div>
              <div>
                <v-btn
                  class="fw-bold btn-color"
                  @click="clearOfficeInCharge"
                >
                  ◀︎
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="5">
            <div>
              <div class="pt-2 fw-bold label">選択済</div>
              <select class="form-control form-height" multiple="multiple" v-model="selectInSelectedList">
                <option v-for="(officeInfo, index) in selectedList.value" :key="index" :value="officeInfo.id">
                  {{officeInfo.operation_office_name}}
                </option>
              </select>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>

  <div v-show="windowInnerWidth <= 800" class="mb-4">
    <v-row class="checkbox-with-number-text-form pl-4 pr-3">
      <v-col cols="3">
        <h6 class="pt-2 pl-4 fw-bold">{{title}}</h6>
      </v-col>
      <v-col>
        <div>
          <div class="pt-2 fw-bold label">一覧</div>
          <select class="form-control form-height" multiple="multiple" v-model="selectInCandidateList">
            <option v-for="(officeInfo, index) in candidateList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
    </v-row>
    <v-row class="checkbox-with-number-text-form pl-4 pr-3">
      <v-col cols="3">
      </v-col>
      <v-col class="text-center">
        <div class="d-flex justify-content-center">
          <div class="mt-10px mr-10px">
            <v-btn
              class="fw-bold btn-color"
              @click="clearOfficeInCharge"
            >
              ◀︎
            </v-btn>
          </div>
          <div class="mt-10px">
            <v-btn
              class="mb-2 fw-bold btn-color"
              @click="addOfficeInCharge"
            >
              ▶︎
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="checkbox-with-number-text-form pl-4 pr-3 mb-2">
      <v-col cols="3">
      </v-col>
      <v-col>
        <div>
          <div class="pt-2 fw-bold label">選択済</div>
          <select class="form-control form-height" multiple="multiple" v-model="selectInSelectedList">
            <option v-for="(officeInfo, index) in selectedList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.btn-color {
  color: white;
  background-color: #3388DC;
}
.label {
  font-size: 14px;
}
.form-height {
  height: 200px;
}
.mt-10px {
  margin-top: 10px;
}
.mr-10px {
  margin-right: 10px;
}
.mt-40px {
  margin-top: 40px;
}
</style>
