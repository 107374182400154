<template>
  <EditRow
    v-if="isEditing"
    :index="props.index"
    v-model="item"
    @remove="remove"
    @save="clearEditing"
  />
  <ViewRow
    v-else
    :index="props.index"
    v-model="item"
    :editable="!props.isWorkEditing"
    :operationOffice="props.operationOffice"
    :updateAuthority="props.updateAuthority"
    :createSpotAuthority="props.createSpotAuthority"
    :isWaitingForSave="props.isWaitingForSave"
    @startEdit="startEdit"
    @setRefuelingAmount="setRefuelingAmount"
    @remove="remove"
    :showFlash="showFlash"
  />
</template>

<script setup>
import DeliveryPlanWork from '@/services/models/DeliveryPlanWork'
import EditRow from "@/components/DailyReportUpdateModalParts/DailyReportWorkListEditRow.vue";
import ViewRow from "@/components/DailyReportUpdateModalParts/DailyReportWorkListViewRow.vue";
import { ref, computed, watch } from "vue";

const props = defineProps({
  index: {
    type: Number
  },
  item: {},
  deliveryPlan: {},
  updateAuthority: {
    type: Boolean,
    default: true,
  },
  operationOffice: {
    type: Object,
  },
  showFlash: {
    type: Function,
  },
  createSpotAuthority: {
    type: Boolean,
  },
  isWorkEditing: {
    type: Boolean,
  },
  isWaitingForSave: {
    type: Boolean,
    default: false,
  }
})

const emits = defineEmits([
  "updateItem",
  "removeRow",
  "setRefuelingAmount"
]);

const item = ref(props.item);

watch(item, () => {
  emits('updateItem', item.value);
});

const isEditing = computed(() => {
  return props.item.editing;
})

function startEdit() {
  item.value.editing = true
  item.value.waiting_for_save = true
  if(item.value.work == 'stopping'){
    item.value.adding = true
  }
  emits('updateItem', item.value);
}

function clearEditing() {
  item.value.editing = false
  emits('updateItem', item.value);
}

function remove() {
  emits('removeRow', item.value)
}

function setRefuelingAmount() {
  emits("setRefuelingAmount")
}

</script>

<style>
.delivery-plan-works-table .v-select {
  width: fit-content;
  min-width: 60%;
}
.delivery-plan-works-table .v-field__field {
}
.delivery-plan-works-table .update-dailyreport-botton {
  border-radius: 3px;
  padding: 3px;
  margin: 1px;
}
.delivery-plan-works-table .update-dailyreport-botton>span {
  display: block;
}
.delivery-plan-works-table .add-button {
  color: white;
  background-color: green;
}
.delivery-plan-works-table .edit-button {
  color: white;
  background-color: blue;
}
.delivery-plan-works-table .save-button {
  color: white;
  background-color: orange;
}
.delivery-plan-works-table .delete-button {
  color: white;
  background-color: red;
}

.delivery-plan-works-table td {
  height: 34px;
}

.delivery-plan-works-table .notification-message {
  color: blue;
  margin: 0px;
}
.delivery-plan-works-table .error-message {
  color: red;
  margin: 0px;
}
.delivery-plan-works-table .work-table-input-box{
  padding: 6px;
  border-radius: 4px;
}
.allocated-text{
  color: red;
}
</style>
