import conn from "@/services/ApiConnection"
import Response from '@/types/responses/getOverTime'
import OverTime from '@/types/OverTime'
import MonthlySummary from '@/types/OverTimeMonthlySummary'
import OperationOfficeSettingsMonthlyReport from '@/types/OperationOfficeSettingsMonthlyReport'
import Api from "@/services/api/ApiServiceFabrick";

class OverTimes {
  public static url = '/api/v1/summaries/over_times';

  public static async divideByThresholds(operationOfficeId: number):Promise<Response> {
    const today = new Date()
    const currentMonth:number = today.getMonth() + 1
    let month:number = currentMonth
    const miliSeccondsInDay = 24 * 60 * 60 * 1000;

    const params: {
      cache: boolean,
      params?: { operation_office_id:number, target_month?: string }
    } = {cache: true};
    if (operationOfficeId) {
      params['params'] = { operation_office_id: operationOfficeId };
    }
    const [res, operationOffices] = await Promise.all([
      conn.get(this.url, params),
      Api().autoCompleteOperationOffices()
    ])

    let setting = {} as OperationOfficeSettingsMonthlyReport

    if (!operationOfficeId) {
        setting = JSON.parse(operationOffices[0].settings_monthly_report) as OperationOfficeSettingsMonthlyReport
    } else {
      operationOffices.forEach((operationOffice) => {
        if (operationOffice.id != operationOfficeId) return true
        setting = JSON.parse(operationOffice.settings_monthly_report) as OperationOfficeSettingsMonthlyReport
      })
    }
    if (setting?.report_deadline_day != 0 && (new Date()).getDate() <= setting?.report_deadline_day) month = currentMonth - 1
    if (month == 0) {
      month = 12
    }

    const response:Response = {
      over_time_data: [],
      alert_count: 0,
      threshold: '- 時間 - 分',
      threshold_secconds: 0,
      start_date: res.data.start_date,
      end_date: res.data.end_date,
      details: res.data,
    }

    let working_threshold = "0:00"
    if (setting?.over_time_working_hour_thresholds && setting.over_time_working_hour_thresholds[month]) {
      working_threshold = setting.over_time_working_hour_thresholds[month]
    }

    const threshold_hours_string = working_threshold?.split(':')[0] ?? '0'
    const threshold_minutes_string = working_threshold?.split(':')[1] ?? '00'
    const threshold_hours =   Number(threshold_hours_string)
    const threshold_minutes = Number(threshold_minutes_string)

    response.threshold = threshold_hours_string+' 時間 '+threshold_minutes_string+' 分'
    response.threshold_secconds = ((threshold_hours * 60) + threshold_minutes) * 60

    res.data.forEach((v:OverTime, i:number) => {
      response.start_date = v.monthly_summary[0]?.start_date
      response.end_date = v.monthly_summary[0]?.end_date

      const data: {
        first_name: string,
        last_name: string,
        driver_id: number,
        monthly_summary: number,
        prediction_monthly_summary: number
      } = {
        first_name: v.first_name,
        last_name: v.last_name,
        driver_id: v.driver_id,
        monthly_summary: v.monthly_summary[0]?.overtime_secconds,
        prediction_monthly_summary: v.monthly_summary[0]?.overtime_secconds,
      }

      let tripCount = v.monthly_summary[0]?.trip_count ?? 1
      if (tripCount == 0) tripCount = 1
      const overtime = v.monthly_summary[0]?.overtime_secconds ?? 0
      const bound_time_by_days = v.bound_time_by_days ?? 0
      const overtimePerDay = overtime / tripCount
      let prediction = overtimePerDay * bound_time_by_days
      if (tripCount > bound_time_by_days) {
        prediction = overtime
      }
      data.prediction_monthly_summary = prediction;

      if (response.threshold_secconds != 0 && response.threshold_secconds < prediction) {
        response.alert_count++
      }

      response.over_time_data.push(data)
    })

    return new Promise((resolve,reject) => {
      resolve(response)
    })
  }
}
export default OverTimes
