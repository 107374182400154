<template>
<GDialog
  v-model="dialogState"
  max-width="30%"
  transition="custom-from-bottom-transition"
>
  <div class="modal-header justify-content-center pt-3">
    <label>
      {{ i18n.global.t('common.confirmationModal.title') }}
    </label>
  </div>
  <div class="row mr-3 ml-3 pl-5 v-theme--light">
    {{ i18n.global.t('common.confirmationModal.confirmDelete') }}
  </div>
  <Buttons
    :isUpdateAuthority="true"
    @cancel="closeModal"
    @submit="submit"
    :label="i18n.global.t('common.confirmationModal.deleteBtn')"
    :hasError="hasError"
  />
</GDialog>

<button @click="dialogState = true" class="material-symbols-outlined">
  delete
</button>

</template>

<script setup>
  import 'gitart-vue-dialog/dist/style.css'
  import { GDialog } from 'gitart-vue-dialog'
  import Api from "@/services/api/ApiServiceFabrick";
  import Buttons from '@/components/SpotParts/ModalButtons'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { round } from "@/services/functions";
  import { ref, defineProps, defineEmits, onMounted } from 'vue'
  import i18n from '@/lang/i18n';

  const props = defineProps(['showFlash', 'item']);
  const emit = defineEmits(['change']);
  const form = ref({});
  const dialogState = ref(false);

  const closeModal = () => {
    dialogState.value = false
  }
  const submit = () => {
    Api().deleteDailyReportLayout(form.value.id).then(() => {
      dialogState.value = false
      props.showFlash(i18n.global.t('pages.common.messages.successDelete'), "success", true)
      emit('change', form.value)
    }).catch((error) => {
      const res = error.response
      switch (res?.status) {
        case 401:
          this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
          break
        case 403:
          location.reload()
          break
        case 422:
          props.showFlash(i18n.global.t('pages.common.messages.failedDelete'), "error", false)
          dialogState.value = false
      }
    })
  };
  onMounted(() => {
    form.value = JSON.parse(JSON.stringify(props.item));
  });
</script>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *, .v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
