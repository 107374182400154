<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex justify-content-between mb-1">
        <h4 class="card-title mt-0">{{ title }}</h4>
        <div class="ml-2 mb-0">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div style="width: fit-content;" >
          <div class="btn-area">
            <create_modal
              v-if="createAuthority"
              :item="{}"
              @updateItem="searchSpotList"
              :showFlash="showFlash"
              :isCreateAuthority="createAuthority"
              :isOperationOffice="isOperationOffice"
              :isSubManagementOffice="isSubManagementOffice"
            >
            </create_modal>
          </div>
        </div>
        <v-btn
          v-show="csvtAuthorities.csvImport"
          class="exec-btn mt-0 ml-1"
          @click="activateModalState"
          >
          CSVアップロード
        </v-btn>
        <UpsertCsvButtons
          v-if="csvtAuthorities.csvImport || csvtAuthorities.csvExport"
          :title="$t('pages.common.button.csvUpsert')"
          :importTitle="$t('pages.spots.csv.importTitle')"
          :importType="importType"
          :exportType="exportType"
          :filterItems="makeSearchParams()"
          :getList="getSpots"
          :authorities="csvtAuthorities"
          elementId="spots-upsert"
        />
      </div>
      <div>
        <SpotList
          :pageInfo="pageInfo"
          :items="dataList"
          :listLoading="listLoading"
          @changeItem="searchSpotList"
          :showFlash="showFlash"
          :isdeleteAuthority="deleteAuthority"
          :isUpdateAuthority="updateAuthority"
          :isOperationOffice="isOperationOffice"
          :isSubManagementOffice="isSubManagementOffice"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
      </div>
    </div>

    <SearchForm
      :loading="searchLoading"
      :isOperationOffice="isOperationOffice"
      :isSubManagementOffice="isSubManagementOffice"
      :isManagementOffice="isManagementOffice"
      @formchange="handleChangeSerarchForm"
      @search="searchSpotList"
    />
    <FileUploadModal
      :modalState="modalState"
      title='CSVアップロード'
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="spots"
      @closeModal="inactivateModalState"
    >
    <template v-slot:supplementary-message>
      <div v-if="!isOperationOffice">
        CSVに設定するoperation_office_idは <span class="sample-file-download" style="color: #3C8DBC" @click="moveToOperationOfficePage()">こちら</span> のID欄をご覧ください。
      </div>
      <div v-if="isOperationOffice">
        現在ログイン中のoperation_office_idは {{ operationOfficeId }} です。
      </div>
    </template>
    </FileUploadModal>
  </div>
</template>

<script>
  import SearchForm from "@/components/SpotParts/SpotSearchForm"
  import SpotList from "@/components/SpotParts/SpotList"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import CreateModal from "@/components/SpotParts/SpotCreateModal"
  import FileUploadModal from "@/components/Files/UploadModal"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import {ref} from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import UpsertCsvButtons from "@/components/Common/UpsertCsvButtons"

  export default {
    props: {
      showFlash: {
        type: Function
      }
    },
    data() {
      return {
        title: "地点一覧",
        form: {},
        currentPage: 1,
        totalPage: 0,
        dataList: [],
        selectedDeliveryPlanIds: [],
        selectOutputOptions: {},
        searchLoading: false,
        listLoading: false,
        beforeSearchParams: null,
        uploadType: "App\\Services\\Csv\\Imports\\SpotService",
        modalState: ref(false),
        isOperationOffice: ref(false),
        isSubManagementOffice: ref(false),
        isManagementOffice: ref(false),
        operationOfficeId: ref(),
        pageName: "manageSpot",
        importType: "UpsertImportSpot",
        exportType: "UpsertExportSpot",
        createAuthority: false,
        updateAuthority: false,
        deleteAuthority: false,
        csvtAuthorities: {
          csvImport: false,
          csvExport: false
        }
      }
    },
    setup() {
      const { loginWithRedirect } = useAuth();
      return { loginWithRedirect }
    },
    mounted() {
      document.title = this.title;
      this.getAuthorities()
      this.getSpots()
      Api().getSelfAccount().then((data) => {
        this.isManagementOffice = data.login_organization_type == 'App\\Models\\ManagementOffice'
        this.isSubManagementOffice = data.login_organization_type == 'App\\Models\\SubManagementOffice'
        if (data.login_organization_type == "App\\Models\\OperationOffice") {
          this.isOperationOffice = true
          this.operationOfficeId = data.login_organization_id
        }
      })
    },
    watch: {
      currentPage: {
        handler() {
          this.getSpots(this.currentPage, false)
        }
      }
    },
    methods: {
      async getAuthorities() {
        const authorityList = await AuthorityCacheApi.getWithParse();
        this.createAuthority = !!authorityList["octlink.spot.create"];
        this.updateAuthority = !!authorityList["octlink.spot.update"];
        this.deleteAuthority = !!authorityList["octlink.spot.delete"];
        this.csvtAuthorities.csvImport = !!authorityList["octlink.spot.input"];
        this.csvtAuthorities.csvExport = !!authorityList["octlink.spot.output"];
      },
      handleChangeSerarchForm(form) {
        this.form = form
      },
      handleSelectDeliveryPlanId(selectedDeliveryPlanIds) {
        this.selectedDeliveryPlanIds = selectedDeliveryPlanIds
      },
      searchSpotList() {
        this.searchLoading = true
        this.getSpots()
      },
      getSpots(page = 1, updateOption = true) {
        this.listLoading = true
        let req
        if (updateOption || !this.beroreSearchParams) {
          req = this.makeSearchParams(page)
          this.beroreSearchParams = req
        } else {
          req = this.beroreSearchParams
          req.page = page
        }

        Api().getSpots(req).then((data) => {
          this.dataList = data.items
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.searchLoading = false
          this.listLoading = false
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
              this.showFlash("一覧の取得に失敗しました。", "error", false)
          }
          this.listLoading = false
          this.searchLoading = false
        })
      },
      makeSearchParams(page){
        const ret = {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_officeable_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          name: this.form?.name,
          address: this.form?.address,
        }
        return ret
      },
      convertDateTimeTodate(datetime) {
        const ret = datetime?.toISOString() || ''
        return ret.split('T')[0].replaceAll('-', '/')
      },
      inactivateModalState (isReacquireGroup) {
        this.modalState = false
        if (isReacquireGroup) this.getSpots()
      },
      activateModalState () {
        this.modalState = true
      },
      moveToOperationOfficePage () {
        this.$router.push('/operation-offices/')
      }
    },
    components: {
      SearchForm,
      SpotList,
      pagenate: PagenateItems,
      create_modal: CreateModal,
      FileUploadModal,
      ManualLink,
      UpsertCsvButtons,
    }
  }
</script>

<style scoped>
.content {
  min-width: 58vw;
}
</style>
<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
</style>
