<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!hide">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="form.managementOffice"
          :error-messages="errors.managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
        <SingleOperationOfficeAutoComplete
          v-model="form.operationOffice"
          :managementOffice="form.managementOffice"
          :error-messages="errors.operationOffice"
          selectFirstIfOnlyOne
          :clearable="operationOfficeClearable"
        />
        <GroupAutoComplete
          v-model="form.group"
          :managementOffice="form.managementOffice"
          :operationOffices="operationOffices"
          :error-messages="errors.group"
        />
        <DepartmentAutoComplete
          v-model="form.department"
          :operationOffices="operationOffices"
          :error-messages="errors.department"
          :selectFirstIfOnlyOne="isDepartment"
        />
        <VehicleAutoComplete
          v-model="form.vehicle"
          :selectedDepartment="form.department"
          :managementOffice="form.managementOffice"
          :operationOffices="operationOffices"
          :error-messages="errors.vehicle"
        />
        <DriverAutoComplete
          v-model="form.driver"
          :selectedDepartment="form.department"
          :operationOffices="operationOffices"
          :error-messages="errors.driver"
        />
        <div class="p-1">
          <v-radio-group v-model="form.selectDateType" class="radio-group-row">
            <v-radio value="leaving" label="出庫日" color="blue"></v-radio>
            <v-radio value="returning" label="帰庫日" color="blue"></v-radio>
          </v-radio-group>
        </div>
        <validation-errors :messages="errors.end_date">
          <div style="font-size: 12px" class="d-flex justify-space-between">
            <Datepicker
              locale="jp"
              :enable-time-picker="false"
              class="m-1"
              v-model="form.start_date"
              format="yyyy/MM/dd"
              auto-apply
              :close-on-auto-apply="true"
            />
            <p class="m-auto">
              ~
            </p>
            <Datepicker
              locale="jp"
              :enable-time-picker="false"
              class="m-1"
              position="right"
              v-model="form.end_date"
              format="yyyy/MM/dd"
              :error="errors.end_date?.length > 0"
              auto-apply
              :close-on-auto-apply="true"
            />
          </div>
        </validation-errors>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons m-auto">
          <v-btn
            class="mr-5 ma-2 exec-btn"
            @click="handleClickSearch"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ searchBtnLabel }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" @click="clearForm"> リセット </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import GroupAutoComplete from '@/components/AutoCompletes/GroupAutoComplete';
import DepartmentAutoComplete from '@/components/AutoCompletes/DepartmentAutoComplete';
import VehicleAutoComplete from '@/components/AutoCompletes/VehicleAutoComplete';
import DriverAutoComplete from '@/components/AutoCompletes/DriverAutoComplete';
import ValidationErrors from '@/components/Common/ValidationErrors';
import { getDateRangeDays } from '@/services/functions';
import i18n from '@/lang/i18n';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const MAX_FILTER_DAYS = 21;

export default {
  setup() {
    return {
      i18n
    }
  },
  props: {
    loading: {},
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
    isManagementOffice: {
      type: Boolean,
      default: false,
    },
    isDepartment: {
      type: Boolean,
      default: false,
    },
    defaultBackDays: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      form: {
        selectDateType: 'leaving',
        start_date: new Date(),
        end_date: new Date(),
      },
      searchBtnLabel: '実行',
      hide: false,
      errors: {},
    };
  },
  computed: {
    searchAreaClass() {
      return this.hide
        ? 'search-area-hide p-4'
        : 'search-area p-4';
    },
    title() {
      return this.hide ? '' : 'フィルター';
    },
    managementOfficeClearable() {
      return this.isManagementOffice;
    },
    operationOfficeClearable() {
      return this.isManagementOffice || this.isSubManagementOffice;
    },
    operationOffices() {
      if (this.form.operationOffice) return [this.form.operationOffice]
      return null
    },
  },
  methods: {
    changeSearchButton() {
      this.searchBtnLabel = this.loading ? '実行中...' : '実行';
    },
    handleClickSearch() {
      if (this.validateForm()) {
        this.$emit('search', this.form);
      }
    },
    clearForm() {
      this.form = {
        managementOffice: this.managementOfficeClearable ? undefined : this.form.managementOffice ,
        operationOffice: this.operationOfficeClearable ? undefined : this.form.operationOffice,
        group: undefined,
        department: undefined,
        vehicle: undefined,
        driver: undefined,
        selectDateType: 'leaving',
        start_date: new Date(),
        end_date: new Date(),
      };
    },
    toggleSidebar() {
      this.hide = !this.hide;
    },
    validateForm() {
      this.errors = {};
      let ret = true;
      if (!this.form.operationOffice) {
        this.errors.operationOffice = ['選択してください。'];
        ret = false;
      }
      if (
        !this.form.start_date ||
        !this.form.end_date ||
        this.form.start_date > this.form.end_date
      ) {
        this.errors.end_date = ['範囲を正しく指定してください。'];
        ret = false;
      } else if (
        getDateRangeDays(this.form.start_date, this.form.end_date) > MAX_FILTER_DAYS
      ) {
        this.errors.end_date = [
          `${i18n.global.t('pages.common.filter.errorMssages.range')}${MAX_FILTER_DAYS}${i18n.global.t('pages.common.filter.errorMssages.pleaseSelectWithinDays')}`
        ];
        ret = false;
      }
      return ret;
    },
    makeInitialForms() {
      this.form.start_date = new Date();
      if (this.isOperationOffice || this.isDepartment) {
        return this.form.start_date.setDate(
          this.form.start_date.getDate() - this.defaultBackDays
        );
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.$emit('formchange', this.form);
      },
      deep: true,
    },
    loading: {
      handler() {
        this.changeSearchButton();
      },
    },
    isOperationOffice: {
      handler() {
        this.makeInitialForms()
      },
    },
    isDepartment: {
      handler() {
        this.makeInitialForms()
      },
    },
  },
  components: {
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
    GroupAutoComplete,
    DepartmentAutoComplete,
    VehicleAutoComplete,
    DriverAutoComplete,
    Datepicker,
    ValidationErrors,
  },
};
</script>

<style>
.search-area {
  max-width: 335px;
  width: 45% !important;
  border-left: solid 1px #b3b8bb;
  background-color: white;
}
.search-area .dp__input_reg {
  font-size: 12px;
}
.dp__action_buttons {
  display: flex;
  width: 80%;
  text-align: right;
}
</style>
