export default {
  title: {
    list: "アカウント一覧",
    create: "アカウント作成",
    edit: "アカウント編集"
  },
  id: "ID",
  loginId :"ログインID",
  accountName: "アカウント名",
  email: "Eメール",
  officeableType: "会社区分",
  officeableName: "所属組織",
  managementOffice: "管理会社",
  subManagementOffice: "準管理会社",
  operationOffice: "運営会社",
  installationOffice: "設置会社",
  department: "組織",
  role: "役割",
  driverId: "ドライバーID",
  telephoneNumber: "電話番号",
  status: "ステータス",
  updatedAt: "最終更新日",
  updatedBy: "最終更新者",
  administrator: "管理者",
  driver: "ドライバー",
  accountCategory: "アカウント区分",
  confirmationModal: {
    valid: "有効",
    invalid: "無効",
    account: "アカウントを",
    statusConfirm: "にします。よろしいですか？"
  },
  csv: {
    importTitle: "アカウントの入力",
    itTenkoTitle: "IT点呼連携データ",
    itTenkoImport: "IT点呼連携データ入力",
    itTenkoExport: "IT点呼連携データ出力",
    itTenkoExportMessage: "出力する運営会社を選択してください。",
    ItTenkoImportTitle: "IT点呼連携データの入力",
    itTenkoImportMessage: "ファイルを選択してください。「IT点呼連携データ出力」でダウンロードしたファイルを編集して、こちらからアップロードします。"
  },
  messages: {
    badRequestForDelete: '削除できませんでした。権限が付与されています。',
    getListError: "アカウント一覧の取得に失敗しました。",
    passwordGenerationError: "パスワードの自動生成にはドライバーIDの入力が必要です。",
    mailGenerationError: "メールアドレスの自動生成には所属組織の選択とドライバーIDの入力が必要です。",
    infoMessage: "ログインに必要な情報です。大切に保管してください",
    passwordMessage: "お手持ちのパスワードをご確認ください。"
  },
  form: {
    passwordExpiration: "PWD有効期限",
    emailAddress: "メールアドレス",
    automaticIssuanceEmailAddress: "メールアドレスを自動発行する",
    NoEmailAddress: "メールアドレス無し",
    tempEmailAddress: "一時的なメールアドレス",
    password: "パスワード",
    driverMode: "ドライバーモード",
    operationVerificationAccount: "動作検証用アカウント",
    name: "名前",
    firstName: "名",
    lastName: "姓",
    nameKana: "名前(カナ)",
    firstNameKana: "名(カナ)",
    lastNameKana: "姓(カナ)",
    affiliationDepartment: "所属組織",
    telephoneNumber: "電話番号",
    idExternalAsp: "外部ASP接続ID",
    passExternalAsp: "外部ASP接続PASSコメント",
    comment: "コメント",
    sendCvrUploadMail: "CVRアップロード完了通知",
    button: {
      passwordSetting: "パスワードを任意で設定する",
      passwordAutoGenerate: "パスワードを自動発行する",
      autoCreate: "自動作成",
    }
  },
  delete: {
    tooltip: "権限が付与されているため削除できません。",
  }
}
